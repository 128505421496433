// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Buffer } from 'buffer'
import debug from '../logger'
import { sensorChanged } from '../reducers/timeSeriesReducer'

export const baseUrl = '/api'

export const localGwApi = createApi({
  reducerPath: 'localGwApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Add proper authentication handling
      // eslint-disable-next-line
      headers.set('Authorization', 'Basic ' + new Buffer.from('admin' + ':' + getState().login.password).toString('base64'))
      return headers
    }
  }),
  tagTypes: ['Sensor', 'Group', 'Alert', 'Waveform'],
  endpoints: (builder) => ({
    //===============================
    //       Sensor endpoints
    //===============================
    getSensors: builder.query({
      query: () => '/node',
      // eslint-disable-next-line no-unused-vars
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          debug(data)
          dispatch(sensorChanged(data[0].serial))
        } catch (err) {
          dispatch(sensorChanged(''))
        }
      },
      providesTags: ['Sensor']
    }),
    getUnregisteredSensors: builder.query({
      query: () => ({
        url: '/node',
        params: {
          registered: false
        }
      }),
      providesTags: ['Sensor']
    }),
    addSensor: builder.mutation({
      query: (payload) => ({
        url: '/node',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }),
      invalidatesTags: ['Sensor']
    }),
    editSensor: builder.mutation({
      query: (payload) => {
        return {
          url: `/node/admin/${payload.id}`,
          method: 'PUT',
          body: payload.body,
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      },
      invalidatesTags: ['Sensor']
    }),
    deleteSensor: builder.mutation({
      query: (id) => ({
        url: `/node/${id}`,
        method: 'delete',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        responseHandler: 'text'
      }),
      invalidatesTags: ['Sensor', 'Group', 'Waveform', 'Alert']
    }),
    //===============================
    //       Group endpoints
    //===============================
    getGroup: builder.query({
      query: () => '/group',
      providesTags: ['Group']
    }),
    editGroup: builder.mutation({
      query: (group) => {
        return {
          url: `/group/${group.id}`,
          method: 'PUT',
          body: group,
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      },
      invalidatesTags: ['Group']
    }),
    addGroup: builder.mutation({
      query: (group) => ({
        url: '/group',
        method: 'POST',
        body: group,
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }),
      invalidatesTags: ['Group']
    }),
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `/group/${id}`,
        method: 'delete',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }),
      invalidatesTags: ['Group', 'Sensor']
    }),
    //===============================
    //     Data fetch endpoints
    //===============================
    getTimeSeries: builder.query({
      query: (args) => {
        const { nodeId, from_date, to_date } = args
        return {
          url: `measurements/${nodeId}`,
          params: { from_date, to_date }
        }
      }
    }),
    //===============================
    //   Firmware update endpoints
    //===============================
    sendGwFirmware: builder.mutation({
      query: (fw) => ({
        url: `gw/update`,
        method: 'POST',
        body: fw
      })
    }),
    getUpdateStatus: builder.query({
      query: () => ({
        url: '/update-status',
        // eslint-disable-next-line no-unused-vars
        validateStatus: (response, _) => {
          return response.status !== 201 && response.status < 400
        }
      })
    }),
    sendNodeFirmware: builder.mutation({
      query: (fw) => ({
        url: 'node/update',
        method: 'POST',
        body: fw
      })
    }),
    //===============================
    //        Alerts
    //===============================
    getAlerts: builder.query({
      query: (args) => {
        const { nodeId, from_date, to_date, states } = args
        return {
          url: `/alerts`,
          params: {
            from_date,
            to_date,
            states,
            node: nodeId
          }
        }
      },
      providesTags: ['Alert']
    }),
    editAlert: builder.mutation({
      query: (args) => {
        const { alert_id, ...rest } = args
        return {
          url: `/alerts/${alert_id}`,
          method: 'PUT',
          body: JSON.stringify(rest),
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      },
      invalidatesTags: ['Alert']
    }),
    //===============================
    //         Settings
    //===============================
    getSettings: builder.query({
      query: () => '/settings'
    }),
    setSettings: builder.mutation({
      query: (body) => ({
        url: '/settings',
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
    }),
    getTime: builder.query({
      query: () => 'time'
    }),
    getUnit: builder.query({
      query: () => 'unit'
    }),
    //===============================
    //       Raw measurements
    //===============================
    startRawData: builder.mutation({
      query: (serial) => ({
        url: `/node/waveform/${serial}`,
        method: 'POST'
      }),
      invalidatesTags: ['Waveform']
    }),
    waveformStatus: builder.query({
      query: (args) => {
        const { serial } = args
        debug('WaveformStatus', serial)
        return `/node/waveform/${serial}`
      },
      providesTags: ['Waveform']
    }),
    getWaveform: builder.query({
      query: ({ serial }) => ({
        url: `/node/waveform/data/${serial}`,
        responseHandler: 'text'
      })
    }),
    //===============================
    //       Raw version
    //===============================
    getVersion: builder.query({
      query: () => 'version'
    }),
    //===============================
    //       Email
    //===============================
    sendTestEmail: builder.mutation({
      query: (body) => ({
        url: '/test/email',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
    })
  })
})

export const {
  useGetSensorsQuery,
  useGetTimeSeriesQuery,
  useGetUnregisteredSensorsQuery,
  useAddSensorMutation,
  useEditSensorMutation,
  useDeleteSensorMutation,
  useGetGroupQuery,
  useEditGroupMutation,
  useAddGroupMutation,
  useDeleteGroupMutation,
  useSendGwFirmwareMutation,
  useGetUpdateStatusQuery,
  useSendNodeFirmwareMutation,
  useLazyGetTimeSeriesQuery,
  useGetAlertsQuery,
  useLazyGetAlertsQuery,
  useEditAlertMutation,
  useGetSettingsQuery,
  useSetSettingsMutation,
  useGetTimeQuery,
  useGetUnitQuery,
  useStartRawDataMutation,
  useWaveformStatusQuery,
  useLazyWaveformStatusQuery,
  useLazyGetWaveformQuery,
  useGetVersionQuery,
  useSendTestEmailMutation
} = localGwApi

async function poll(url, end_code, redirect = false) {
  // Delay mechanism
  const first = await fetch(url)

  if (first.status === end_code && first.redirected === redirect) {
    return first.text()
  }

  const wait = (ms = 10000) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  let run = true
  let result

  const pollFunc = async () => {
    const res = await fetch(url)
    debug('PollingResponse from', url, res)
    if (res.status === end_code && res.redirected === redirect) {
      run = false
      return res
    } else {
      const text = await res.text()
      throw new Error(text)
    }
  }

  for (let i = 0; i < 30; ++i) {
    if (!run) {
      break
    }
    try {
      await wait()
      const res = await pollFunc()
      result = { res }
    } catch (reason) {
      result = { reason }
    }
  }

  if (result.reason) {
    throw new Error(result.reason)
  }

  return result.res.text()
}

// Used for version polling and checking if update is on going.
export async function pollVersion() {
  const url = `${baseUrl}/version`
  return poll(url, 200)
}

export async function pollRawData(serial) {
  const url = `${baseUrl}/node/waveform/poll/${serial}`
  return poll(url, 302)
}

export async function getUpdateStatus() {
  const url = `${baseUrl}/update-status`
  return fetch(url, { cache: 'no-cache' })
}

export async function getLicenses(password) {
  const url = `${baseUrl}/licenses`
  const headers = new Headers()
  headers.set('Authorization', 'Basic ' + new Buffer.from('admin' + ':' + password).toString('base64'))

  const res = await fetch(url, { headers: headers })
  if (res.status !== 200) {
    throw res
  }
  return res.blob()
}
