import React, { useState } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { PrettyDate } from './Elements'
import VirtualList from './VirtualList'
import EditSensor, { DeleteSensor } from './EditSensor'
import BatteryAlertRoundedIcon from '@mui/icons-material/BatteryAlertRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

const Limits = ({ warning, error }) => {
  if (!(warning && error)) {
    return <Typography sx={{ fontSize: 'inherit' }}> No limit set </Typography>
  }
  return (
    <Stack direction='row' spacing={'4px'}>
      <Typography sx={{ fontSize: 'inherit' }}>{warning}</Typography>
      <Typography sx={{ fontSize: 'inherit' }}>/</Typography>
      <Typography sx={{ fontSize: 'inherit' }}>{error}</Typography>
    </Stack>
  )
}

const SensorTable = ({ sensors, groups, ...rest }) => {
  const [currentSensor, setCurrent] = useState(undefined)

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
      xs: true
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 160
    },
    {
      field: 'serial',
      headerName: 'Serial',
      width: 80,
      xs: true
    },
    {
      field: 'last_message',
      headerName: 'Last message',
      width: 200,
      md: true,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 'inherit' }}>
          <PrettyDate date_string={params.value}></PrettyDate>
        </Typography>
      )
    },
    {
      field: 'measurement_interval',
      headerName: 'Measurement interval',
      width: 120,
      sm: true
    },
    {
      field: 'group',
      headerName: 'Group',
      width: 80,
      valueGetter: ({ row }) => {
        if (row.group !== undefined) {
          return groups[row.group]?.name
        } else {
          return ''
        }
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 120,
      xl: true,
      valueGetter: ({ row }) => {
        if (row.type === 'measure_route') {
          return 'Measuring sensor'
        } else if (row.type === 'route') {
          return 'Mesh relay sensor'
        } else {
          return 'Unknown type'
        }
      }
    },
    {
      field: 'temp_limits',
      headerName: 'Temperature limits',
      valueGetter: ({ row }) => [row.temp_warning, row.temp_error],
      renderCell: ({ value }) => {
        return <Limits warning={value[0]} error={value[1]} />
      },
      width: 120,
      xl: true
    },
    {
      field: 'vel_limits',
      headerName: 'Velocity limits',
      width: 120,
      valueGetter: ({ row }) => [row.velocity_warning, row.velocity_error],
      renderCell: ({ value }) => {
        return <Limits warning={value[0]} error={value[1]} />
      },
      xl: true
    },
    {
      field: 'version',
      headerName: 'SW Version',
      width: 120
    },
    {
      field: 'battery_alert',
      headerName: 'Battery alert',
      width: 50,
      renderCell: ({ value }) => {
        if (value) {
          return <BatteryAlertRoundedIcon fontSize='small' color='error' />
        }
        return <> No </>
      },
      noSort: true,
      xs: true
    },
    {
      field: 'anomaly_detection',
      headerName: 'Anomaly',
      valueGetter: ({ row }) => {
        if (row.anomaly_detection !== undefined) {
          return 'Enabled'
        } else {
          return 'Disabled'
        }
      }
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 40,
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            setCurrent(value)
          }}
        >
          {' '}
          <EditRoundedIcon sx={{ fill: 'black' }} />{' '}
        </IconButton>
      ),
      noSort: true,
      xs: true
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 40,
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => <DeleteSensor sensor={value} />,
      sm: true,
      noSort: true
    }
  ]

  const onClose = () => {
    setCurrent(undefined)
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <VirtualList rows={sensors} columns={columns} style={{ height: '1000px' }} defaultCollapse {...rest} />
      {currentSensor !== undefined && (
        <EditSensor groups={groups} onlyModal={true} modalOpen={true} modalClose={onClose} sensor={currentSensor} />
      )}
    </Stack>
  )
}

export default SensorTable
