import * as React from 'react'
const VelocityWarning = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 24
    }}
    {...props}
  >
    <path
      fill={color}
      d='M0 2.158C0 .966.966 0 2.158 0h19.684C23.034 0 24 .966 24 2.158v19.684A2.158 2.158 0 0 1 21.842 24H2.158A2.158 2.158 0 0 1 0 21.842V2.158Z'
    />
    <path
      fill='#fff'
      d='M17.884 8.323a2.734 2.734 0 0 0-.851 1.016c-.29.484-.455.726-.712.726-.256 0-.42-.233-.71-.726-.291-.494-.707-1.21-1.55-1.21-.841 0-1.248.697-1.548 1.21-.3.513-.454.726-.706.726-.252 0-.421-.233-.711-.726-.29-.494-.707-1.21-1.549-1.21S8.3 8.826 8 9.34c-.3.513-.45.726-.711.726-.262 0-.421-.233-.707-.726a2.734 2.734 0 0 0-.851-1.016.484.484 0 0 0-.649.65.484.484 0 0 0 .155.182c.216.183.39.41.508.668.295.513.702 1.21 1.544 1.21.842 0 1.253-.697 1.548-1.21.295-.513.45-.726.711-.726.262 0 .421.232.707.726.285.493.706 1.21 1.553 1.21s1.248-.697 1.548-1.21c.3-.513.45-.726.712-.726.26 0 .42.232.71.726.291.493.707 1.21 1.55 1.21.841 0 1.248-.697 1.548-1.21.111-.255.276-.483.483-.668a.484.484 0 0 0 .223-.416.483.483 0 0 0-.697-.416ZM17.884 13.161a2.733 2.733 0 0 0-.851 1.016c-.29.484-.455.726-.712.726-.256 0-.42-.232-.71-.726-.291-.493-.707-1.21-1.55-1.21-.841 0-1.248.697-1.548 1.21-.3.513-.454.726-.706.726-.252 0-.421-.232-.711-.726-.29-.493-.707-1.21-1.549-1.21S8.3 13.664 8 14.177c-.3.513-.45.726-.711.726-.262 0-.421-.232-.707-.726a2.733 2.733 0 0 0-.851-1.016.483.483 0 0 0-.649.65.484.484 0 0 0 .155.182c.216.183.39.41.508.668.295.513.702 1.21 1.544 1.21.842 0 1.253-.697 1.548-1.21.295-.513.45-.726.711-.726.262 0 .421.232.707.726.285.494.706 1.21 1.553 1.21s1.248-.697 1.548-1.21c.3-.513.45-.726.712-.726.26 0 .42.232.71.726.291.494.707 1.21 1.55 1.21.841 0 1.248-.697 1.548-1.21.111-.255.276-.482.484-.668a.484.484 0 0 0 .203-.416.485.485 0 0 0-.678-.416Z'
    />
  </svg>
)
export default VelocityWarning
