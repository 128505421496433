import React, { Fragment, useState } from 'react'
import { Stack, Box, Button, Table, TableBody, TableCell, TableRow, CircularProgress } from '@mui/material'
import debug from '../logger'
import { getLicenses } from '../api/localGwApi'
import useNotify from '../hooks/useNotify'
import { useSelector } from 'react-redux'
import LegalText from './LegalText'

const VersionTab = ({ backendVersion }) => {
  const [onNotify] = useNotify()
  const [loading, setLoading] = useState(false)
  const password = useSelector(({ login }) => login.password)
  const saveFile = async (blob) => {
    debug(blob)
    const a = document.createElement('a')
    a.download = `licenses.zip`
    a.href = URL.createObjectURL(blob)
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
    })
    a.click()
  }

  const onClick = () => {
    if (loading) {
      return
    }
    setLoading(true)
    getLicenses(password)
      .then((blob) => {
        saveFile(blob)
        setLoading(false)
      })
      .catch((res) => {
        onNotify(res)
        setLoading(false)
      })
  }

  return (
    <Stack spacing={2}>
      <Table>
        <TableBody>
          {Object.entries(backendVersion).map(([key, val]) => {
            return (
              <Fragment key={key}>
                <TableRow>
                  <TableCell>{key}</TableCell>
                  <TableCell>{val}</TableCell>
                </TableRow>
              </Fragment>
            )
          })}
          <TableRow>
            <TableCell>UI version:</TableCell>
            <TableCell>{process.env.REACT_APP_VERSION}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Stack flexDirection={'row-reverse'} justifyContent={'space-between'}>
        <Box>
          <Button sx={{ minWidth: '200px', margin: '16px' }} disabled={loading} onClick={onClick} variant='contained'>
            {loading ? (
              <Stack spacing={2} direction='row'>
                Downloading
                <CircularProgress sx={{ color: 'white' }} size='20px' />
              </Stack>
            ) : (
              <>Download licenses</>
            )}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'end', padding: '15px' }}>
          <LegalText
            sx={{
              fontSize: '9px',
              maxWidth: '599px'
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default VersionTab
