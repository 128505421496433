import React from 'react'
import { Stack, Box, Grid, Typography } from '@mui/material'
import AlertList from './AlertList'
import DashBoardCard from './DashBoardCard'
import ListSkeleton from './ListSkeleton'
import { useGetAlertsQuery, useGetSensorsQuery } from '../api/localGwApi'
import Clock from './Clock'

const DashboardTab = () => {
  const { data: rows } = useGetAlertsQuery(
    {
      states: ['new', 'active']
    },
    {
      pollingInterval: 540000
    }
  )

  const { data: sensors } = useGetSensorsQuery(
    {},
    {
      pollingInterval: 540000
    }
  )

  const warnings = rows?.reduce((acc, row) => {
    if (row.type === 'warning') {
      return acc + 1
    }
    return acc
  }, 0)

  const errors = rows?.reduce((acc, row) => {
    if (row.type === 'error') {
      return acc + 1
    }
    return acc
  }, 0)

  const anomaly = rows?.reduce((acc, row) => {
    if (row.type === 'anomaly') {
      return acc + 1
    }
    return acc
  }, 0)

  const low_battery = sensors?.reduce((acc, sensor) => {
    if (sensor.battery_alert) {
      return acc + 1
    }
    return acc
  }, 0)

  const online = sensors?.reduce((acc, sensor) => {
    if (sensor.online) {
      return acc + 1
    }
    return acc
  }, 0)

  return (
    <Grid container spacing={{ xs: '10px', lg: '44px' }} height={'calc(100% - 64px)'}>
      <Grid xs={12} zeroMinWidth item sx={{ display: { md: 'none' } }}>
        <Clock />
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={4} zeroMinWidth item>
        <DashBoardCard variant='alert_warning' values={[errors, warnings]} />
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={4} zeroMinWidth item>
        <DashBoardCard variant='anomaly' values={[anomaly]} />
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={4} zeroMinWidth item>
        <DashBoardCard variant='sensors' values={[online, low_battery, sensors?.length]} />
      </Grid>
      <Grid xs={12} height={'90vh'} item container spacing={1}>
        <Grid xs={12} sm={12} item>
          <Stack direction='column' justifyContent='space-between' spacing={2}>
            <Typography variant='h6'>{rows?.length !== 0 ? <> Active Alerts ({sensors?.length}) </> : <> NO ACTIVE ALERTS </>}</Typography>
            <Typography variant='h6'>{rows?.length !== 0 ? <> </> : <> Active and new alerts will be shown here </>}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} display='flex' height='100%'>
          {rows !== undefined ? (
            <>
              {rows?.length !== 0 && (
                <Box style={{ display: 'flex', flex: '1 1 auto', marginBottom: '3rem' }}>
                  <AlertList alerts={rows} style={{ width: '100%' }} showNew showActive dismiss />
                </Box>
              )}
            </>
          ) : (
            <ListSkeleton row_count={3} />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardTab
