import * as React from 'react'
const NotificationError = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 -1.5 24 22'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 22
    }}
    {...props}
  >
    <path
      fill={color}
      d='M10.227 1.024.274 18.262a2.048 2.048 0 0 0 1.774 3.071h19.904a2.048 2.048 0 0 0 1.773-3.071L13.774 1.024a2.048 2.048 0 0 0-3.546 0Z'
    />
    <path
      fill='#fff'
      d='M12.13 6.34h-.26c-.638 0-1.157.519-1.157 1.158v5.532c0 .64.519 1.157 1.158 1.157h.26c.639 0 1.157-.518 1.157-1.157V7.498c0-.64-.518-1.158-1.158-1.158ZM12 18.575a1.287 1.287 0 1 0 0-2.574 1.287 1.287 0 0 0 0 2.574Z'
    />
  </svg>
)
export default NotificationError
