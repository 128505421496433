import React, { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { PasswordInput } from './Elements'

const Login = ({ error = '', onLogin = () => {} }) => {
  const [password, setPassword] = useState('')

  const beforeLogin = (e) => {
    e.preventDefault()
    onLogin(password)
  }

  return (
    <Stack spacing={3}>
      <Typography variant='h6' sx={{ textAlign: 'center' }}>
        Enter password
      </Typography>
      <Stack spacing={2} alignItems='center'>
        <PasswordInput
          error={error.length > 0}
          helperText={error}
          onChange={(e) => setPassword(e.target.value)}
          onEnter={beforeLogin}
          value={password}
          label='Admin password'
        />
        <Stack direction='row' spacing={2}>
          <Button variant='contained' onClick={beforeLogin}>
            Login
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Login
