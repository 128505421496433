import { createTheme } from '@mui/material'

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: '#ffffff'
          }
        }
      ]
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '10px',
          fontSize: '0.8rem',
          backgroundColor: 'black'
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#f89829'
    },
    secondary: {
      main: '#071d49'
    },
    background: {
      alternative: '#000000'
    },
    warning: {
      main: '#f2c62a'
    },
    anomaly: {
      main: '#1f55aa'
    },
    error: {
      main: '#ee414d'
    },
    alternativeBackground: {
      background: '#121212',
      text: '#ffffff'
    },
    highlightColor: {
      main: '#f9f9f9'
    }
  }
})

export default theme
