import { createSlice } from '@reduxjs/toolkit'
import { xDaysAgo } from '../components/Elements'

export const timeSeriesSlice = createSlice({
  name: 'series',
  initialState: {
    data: {},
    range: {
      from: xDaysAgo(1).toISOString(),
      to: xDaysAgo(0).toISOString()
    },
    sensor: '',
    loading: true
  },
  reducers: {
    addSeries: (state, action) => {
      return {
        ...state,
        data: action.payload
      }
    },
    rangeChanged: (state, action) => {
      return {
        ...state,
        range: action.payload
      }
    },
    sensorChanged: (state, action) => {
      return {
        ...state,
        sensor: action.payload
      }
    },
    loadingChanged: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    }
  }
})

export const { addSeries, sensorChanged, rangeChanged } = timeSeriesSlice.actions

export default timeSeriesSlice.reducer
