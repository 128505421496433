import * as React from 'react'
const TemperatureError = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 -1.5 24 22'
    style={{
      ...style,
      height: style?.height ? style.height : 22,
      width: style?.width ? style.width : 24
    }}
    fill='none'
    {...props}
  >
    <path
      fill={color}
      d='M10.227 1.024.274 18.262a2.048 2.048 0 0 0 1.774 3.071h19.904a2.048 2.048 0 0 0 1.773-3.071L13.774 1.024a2.048 2.048 0 0 0-3.546 0Z'
    />
    <path fill='#fff' d='M12.467 14.216v-2.79a.452.452 0 0 0-.903 0v2.79a1.355 1.355 0 1 0 .903 0Z' />
    <path
      fill='#fff'
      d='M14.273 13.281V8.265a2.258 2.258 0 0 0-4.515 0v5.016a3.157 3.157 0 0 0 2.253 5.369 3.158 3.158 0 0 0 2.262-5.369Zm-.83 3.956a2.255 2.255 0 0 1-2.935-3.422.443.443 0 0 0 .153-.34v-5.21a1.355 1.355 0 0 1 2.71 0v5.21a.443.443 0 0 0 .153.34 2.25 2.25 0 0 1-.081 3.422Z'
    />
  </svg>
)
export default TemperatureError
