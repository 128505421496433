import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Stack, Tooltip } from '@mui/material'
import debug from '../logger'
import UploadCard from './UploadCard'
import CircularProgress from '@mui/material/CircularProgress'

import { useSendGwFirmwareMutation } from '../api/localGwApi'
import useNotify from '../hooks/useNotify'

const steps = ['Select gateway firmware', 'Uploading']

const FirmwareStepper = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [files, setFiles] = useState([])
  const [upload, uploadStatus] = useSendGwFirmwareMutation()
  const [errorMsg, setErrorMsg] = useState([])
  const [onNotify] = useNotify()

  const handleNext = () => {
    debug(activeStep)
    uploadGwFirmware(files[0])
    setActiveStep(1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
    setErrorMsg([])
  }

  const handleFileUpload = (e) => {
    debug(e.target.files)
    setFiles(e.target.files)
  }

  const uploadGwFirmware = (fw) => {
    const data = new FormData()
    data.append('fw_binary', fw)

    upload(data)
      .unwrap()
      .then((res) => {
        console.log(res)
        onNotify({}, 'Upload complete gateway firmware installation starting...')
        setTimeout(() => {
          window.location.reload(false)
        }, 5000)
      })
      .catch((res) => {
        onNotify(res)
      })
  }

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={`step-${index}`}>
              <StepLabel> {label} </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Box>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {activeStep === 0 && (
            <>
              Please select valid gateway firmware file that you have downloaded to your pc. <br />
              NOTE you cannot stop the update after the upload has finished.
            </>
          )}
          {activeStep === 1 &&
            'Please wait for the gateway firmware file to be uploaded, this may take a few minutes depending on the network speed. After this you will be moved to the splash screen where you can see the progress. You will stay in the splashscreen until the update is complete and the gateway has rebooted.'}
        </Typography>
        {activeStep === 0 && (
          <Tooltip title='Update software image must be available in the device that is used to access the application.'>
            <span>
              <UploadCard buttonText={'Select gateway firmware'} files={files} handleFileUpload={handleFileUpload}></UploadCard>
            </span>
          </Tooltip>
        )}
        {activeStep === 1 && (
          <>
            <CircularProgress />
            {errorMsg.length > 0 && typeof errorMsg === Array && (
              <Stack direction='column' sx={{ textAlign: 'left' }}>
                {errorMsg.map((line, index) => (
                  <div key={`output-line-${index}`}> {line} </div>
                ))}
              </Stack>
            )}
            {uploadStatus.isError && uploadStatus.error.error}
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', pt: 2 }}>
        <Button onClick={handleNext} disabled={activeStep === 1 || files.length === 0}>
          {activeStep === 0 && 'Start the installation'}
        </Button>
        {errorMsg.length > 0 && <Button onClick={handleBack}>Back</Button>}
      </Box>
    </Box>
  )
}

export default FirmwareStepper
