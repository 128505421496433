// Import react-date-range css
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { IconButton, ThemeProvider } from '@mui/material'
import { CssBaseline } from '@mui/material'
import theme from './theme/theme'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import store from './store'
import { Provider } from 'react-redux'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import ErrorIcon from '@mui/icons-material/Error'
import CancelIcon from '@mui/icons-material/Cancel'

dayjs.extend(timezone)
dayjs.extend(utc)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            maxSnack={3}
            iconVariant={{
              error: (
                <div style={{ paddingRight: '5px', paddingTop: '5px' }}>
                  <ErrorIcon />
                </div>
              )
            }}
            action={(snackbarId) => (
              <IconButton color='inherit' onClick={() => closeSnackbar(snackbarId)}>
                <CancelIcon />
              </IconButton>
            )}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <CssBaseline />
            <App />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
