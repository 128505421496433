import React, { useState } from 'react'
import GroupTab from './GroupsTab'
import FirmwareTab from './FirmwareTab'
import { Select, Stack, Box, Tab, Tabs, useTheme, useMediaQuery, MenuItem } from '@mui/material'
import SensorTab from './SensorsTab'
import Login from './Login'
import { useGetGroupQuery } from '../api/localGwApi'
import debug from '../logger'
import { useDispatch, useSelector } from 'react-redux'
import { loggedIn } from '../reducers/loginReducer'
import { baseUrl } from '../api/localGwApi'
import { Buffer } from 'buffer'
import SettingsTab from './SettingsTab'
import VersionTab from './VersionTab'
import EditSensor from './EditSensor'
import EditGroup from './EditGroup'

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

const AdminTab = ({ version }) => {
  const [value, setValue] = useState(0)
  const dispatch = useDispatch()
  const login = useSelector((state) => state.login.password?.length)
  const [loginError, setLoginError] = useState('')
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const { data: groups } = useGetGroupQuery()

  debug('AdminTab', login)

  const handleChange = (_, newValue) => {
    setValue(newValue)
    debug(newValue)
  }

  const handleSelect = (e) => {
    setValue(e.target.value)
  }

  const onLogin = (password) => {
    fetch(`${baseUrl}/login`, {
      headers: {
        // eslint-disable-next-line
        Authorization: 'Basic ' + new Buffer.from('admin' + ':' + password).toString('base64')
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(loggedIn(password))
          setLoginError('')
        } else if (res.status === 401) {
          setLoginError('Wrong password.')
        } else {
          dispatch(loggedIn(''))
          setLoginError('')
        }
      })
      .catch((e) => {
        ;(console.error || console.log).call(console, e.stack || e)
        setLoginError('Something went wrong...')
      })
  }

  const onCancel = () => {
    debug('Cancel')
  }

  if (login === 0) {
    return (
      <Box sx={{ height: '80%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <Login onLogin={onLogin} onCancel={onCancel} error={loginError} />
      </Box>
    )
  }

  return (
    <Stack sx={{ width: '100%', backgroundColor: 'common.white', overflowY: 'auto' }} spacing={2}>
      <Stack sx={{ flexDirection: { sm: 'column', md: 'row' } }} justifyContent={'space-between'} spacing={1}>
        {sm ? (
          <Select value={value} onChange={handleSelect}>
            <MenuItem value={0}>Sensors</MenuItem>
            <MenuItem value={1}>Firmware</MenuItem>
            <MenuItem value={2}>Groups</MenuItem>
            <MenuItem value={3}>Settings</MenuItem>
            <MenuItem value={4}>Version</MenuItem>
          </Select>
        ) : (
          <Tabs value={value} visibleScrollbar onChange={handleChange} aria-label='basic tabs example'>
            <Tab label='Sensors' />
            <Tab label='Firmware' />
            <Tab label='Groups' />
            <Tab label='Settings' />
            <Tab label='Version' />
          </Tabs>
        )}
        {value === 0 && <EditSensor adding groups={groups} />}
        {value === 2 && <EditGroup adding />}
      </Stack>
      <TabPanel value={value} index={0}>
        <SensorTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FirmwareTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GroupTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SettingsTab />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <VersionTab backendVersion={version} />
      </TabPanel>
    </Stack>
  )
}

export default AdminTab
