import { Button, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const DeleteConfirmation = ({ title, text, onConfirm, children, ...rest }) => {
  return (
    <Dialog {...rest} onClick={(e) => e.stopPropagation()}>
      <DialogTitle sx={{ paddingBottom: '0px' }} id='delete-title'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-evenly', paddingBottom: '20px' }}>
        <Stack spacing={2} direction={'row'}>
          <Button variant='contained' onClick={() => onConfirm(false)}>
            Cancel
          </Button>
          <Button color='error' variant='contained' onClick={() => onConfirm(true)}>
            Delete
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmation
