import {
  Box,
  Stack,
  Typography,
  Collapse,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { SortIcon } from './Elements'

export const CollapseCell = styled(TableCell)({
  fontSize: '0.8rem'
})

export const StyledCell = styled(CollapseCell)({
  padding: '8px 15px 8px 15px'
})

export const BoldedCell = styled(CollapseCell)({
  fontWeight: 'bold'
})

const VirtualList = ({ rows, columns, CollapseContent, defaultCollapse, style, ...rest }) => {
  const openRows = useRef({})
  const [sortedRows, setSortedRows] = useState(rows)
  const [sortOrder, setSortOrder] = useState({})

  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up('xs'))
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    setSortedRows(rows)
  }, [rows])

  const filter = (col) => {
    if (!(sm || md || lg || xs || xl)) {
      return true
    } else if (col.xs === xs) {
      return true
    } else if (col.sm === sm) {
      return true
    } else if (col.md === md) {
      return true
    } else if (col.lg === lg) {
      return true
    } else if (col.xl === xl) {
      return true
    }
    return false
  }

  let row_content = columns.filter(filter)
  let collapse_content = columns.filter((col) => !filter(col))

  if (row_content.length === 0) {
    row_content = collapse_content
    collapse_content = []
  }

  const onSort = (column) => {
    const copy = [].concat(sortedRows)
    if (typeof column.comparator === 'function') {
      copy.sort(column.comparator)
    } else {
      copy.sort((l, r) => {
        if (l[column.field] > r[column.field]) {
          return 1
        } else if (l[column.field] < r[column.field]) {
          return -1
        }
        return 0
      })
    }

    const order = !sortOrder[column.field]
    if (!order) {
      copy.reverse()
    }

    setSortOrder({ [column.field]: !sortOrder[column.field] })
    setSortedRows(copy)
  }

  const Row = (props) => {
    const [open, setOpen] = useState(!!openRows.current[props['data-index']])
    const index = props['data-index']
    const onOpen = () => {
      if (collapse_content.length === 0) {
        return
      }

      setOpen(!open)
      openRows.current = {
        ...openRows.current,
        [props['data-index']]: !open
      }
    }

    return (
      <Stack {...props}>
        <TableRow
          onClick={onOpen}
          component={Box}
          sx={{ display: 'flex', flexDirection: 'row', backgroundColor: open ? 'highlightColor.main' : '' }}
        >
          {row_content.map((column) => {
            const row = sortedRows[index]
            const value = column.valueGetter ? column.valueGetter({ row: row }) : row[column.field]
            const cell = column.renderCell ? column.renderCell({ value: value }) : value

            return (
              <StyledCell
                component={Box}
                sx={{ width: column.width, display: 'flex', alignItems: 'center', flex: '1 0 auto' }}
                key={column.field}
              >
                {cell}
              </StyledCell>
            )
          })}
        </TableRow>
        <Collapse in={open} timeout={0}>
          <Box sx={{ backgroundColor: 'highlightColor.main' }}>
            {defaultCollapse && <DefaultCollapseContent columns={collapse_content} item={sortedRows[index]} />}
            {CollapseContent && <CollapseContent columns={collapse_content} item={sortedRows[index]} />}
          </Box>
        </Collapse>
      </Stack>
    )
  }

  if (sortedRows === undefined) {
    return <Stack>{'Something is going wrong'}</Stack>
  }

  return (
    <Stack sx={style}>
      <Table
        component={Box}
        sx={{ height: '100%', borderCollapse: 'separate', tableLayout: 'fixed', display: 'flex', flexDirection: 'column' }}
      >
        <TableHead component={Box}>
          <TableRow component={Box} sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'primary.main' }}>
            {row_content.map((column) => (
              <StyledCell
                key={column.field}
                component={Box}
                sx={{
                  width: column.width,
                  display: 'flex',
                  alignItems: 'center',
                  flex: '1 0 auto',
                  color: 'alternativeBackground.text',
                  fontWeight: 'bold'
                }}
              >
                {column.headerName}
                {!column.noSort && (
                  <SortIcon
                    onClick={() => onSort(column)}
                    up={sortOrder[column.field]}
                    down={!sortOrder[column.field]}
                    active={sortOrder[column.field] !== undefined}
                  />
                )}
              </StyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody component={Box} sx={{ height: '100%', width: '100%' }}>
          <Virtuoso
            style={{ height: '100%', width: '100%' }}
            components={{ Item: Row }}
            overscan={1080}
            totalCount={sortedRows.length}
            {...rest}
          />
        </TableBody>
      </Table>
    </Stack>
  )
}

function DefaultCollapseContent({ columns, item }) {
  const ItemRow = ({ label, content }) => {
    return (
      <>
        <StyledCell sx={{ borderBottom: 'none', width: '120px' }} component={Box}>
          <Typography sx={{ fontSize: 'inherit', fontWeight: 'bold' }}>{label}</Typography>
        </StyledCell>
        <StyledCell sx={{ borderBottom: 'none' }} component={Box}>
          <Typography sx={{ fontSize: 'inherit' }} component={'div'}>
            {content}
          </Typography>
        </StyledCell>
      </>
    )
  }

  return (
    <TableContainer sx={{ boxShadow: 1 }}>
      <Table component={Box}>
        <TableBody component={Box}>
          {columns.map((column) => {
            const value = column.valueGetter ? column.valueGetter({ row: item }) : item[column.field]
            const cell = column.renderCell ? (
              <ItemRow label={column.headerName} content={column.renderCell({ value: value })} />
            ) : (
              <ItemRow label={column.headerName} content={value} />
            )
            return (
              <TableRow component={Box} key={column.field}>
                {cell}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VirtualList
