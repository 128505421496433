import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { Stack, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Link, useLocation } from 'react-router-dom'
import TimkenLogo from '../assests/TimkenLogo'
import Clock from './Clock'

// Icons
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { LogoutButton } from './Elements'

const drawerWidth = 80

const SideBar = ({ window, children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const location = useLocation()
  const theme = useTheme()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Stack backgroundColor='alternativeBackground.background' sx={{ height: '100%' }}>
      <Toolbar />
      <List>
        <ListItem disablePadding component={Link} to={'/'}>
          <ListItemButton
            sx={{
              color: location.pathname === '/' ? 'primary.main' : 'alternativeBackground.text',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <DashboardRoundedIcon />
            <ListItemText primary={'Status'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to={'/time-series'}>
          <ListItemButton
            sx={{
              color: location.pathname === '/time-series' ? 'primary.main' : 'alternativeBackground.text',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <TimelineRoundedIcon />
            <ListItemText primary={'Trends'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to={'/alerts'}>
          <ListItemButton
            sx={{
              color: location.pathname === '/alerts' ? 'primary.main' : 'alternativeBackground.text',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ErrorRoundedIcon />
            <ListItemText primary={'Alerts'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to={'/admin'}>
          <ListItemButton
            sx={{
              color: location.pathname === '/admin' ? 'primary.main' : 'alternativeBackground.text',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <AdminPanelSettingsRoundedIcon />
            <ListItemText primary={'Admin'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Box sx={{ display: 'flex', flex: '1 1 auto' }} />
      <LogoutButton />
      <Toolbar />
    </Stack>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            backgroundColor: 'alternativeBackground.text',
            paddingLeft: { xs: '10px', md: '44px' },
            paddingRight: { xs: '10px', md: '44px' }
          }}
        >
          <Grid container>
            <Grid item xs={6} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <TimkenLogo fill={theme.palette.primary.main} style={{ height: 20, width: 'auto' }} />
            </Grid>
            <Grid item xs={6} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography> For more information visit timkensensor.com </Typography>
            </Grid>
            <Grid item xs={0} md={4} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
              <Clock sx={{ display: { xs: 'none', md: 'flex' } }} />
            </Grid>
          </Grid>
          <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor='right'
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: 'alternativeBackground.text',
          padding: { xs: '10px', md: '44px' },
          width: { sm: `calc(100% - ${drawerWidth}px)` }
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default SideBar
