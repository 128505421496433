import * as React from 'react'
const NotificationAnomaly = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 24
    }}
    {...props}
  >
    <path fill={color} d='M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z' />
    <path
      fill='#fff'
      d='M12.192 4.058h-.336c-.826 0-1.496.67-1.496 1.496v7.152c0 .826.67 1.496 1.496 1.496h.336c.826 0 1.496-.67 1.496-1.496V5.554c0-.827-.67-1.496-1.496-1.496ZM12.024 19.874a1.664 1.664 0 1 0 0-3.328 1.664 1.664 0 0 0 0 3.328Z'
    />
  </svg>
)

export default NotificationAnomaly
