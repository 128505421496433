import React from 'react'
import { Stack, ListItem, List, Typography } from '@mui/material'
import { useGetSensorsQuery, useGetUnregisteredSensorsQuery, useGetGroupQuery } from '../api/localGwApi'
import NodeInventoryList from './NodeInventoryList'
import SensorTable from './SensorTable'
import ListSkeleton from './ListSkeleton'

const SensorTab = (props) => {
  const { data: registered } = useGetSensorsQuery()
  const { data: unregistered } = useGetUnregisteredSensorsQuery()
  const { data: groups } = useGetGroupQuery()

  if (registered !== undefined && unregistered !== undefined && groups !== undefined) {
    return (
      <List disablePadding>
        {unregistered.length > 0 && (
          <>
            <ListItem>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                {`Unregistered sensors (${unregistered?.length})`}
              </Typography>
            </ListItem>
            <ListItem sx={{ padding: '0px 0px 40px' }}>
              <NodeInventoryList groups={groups} sensors={unregistered} />
            </ListItem>
          </>
        )}
        {registered.length > 0 && (
          <>
            <ListItem>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                {`Registered sensors (${registered?.length})`}
              </Typography>
            </ListItem>
            <ListItem sx={{ padding: 0, marginBottom: '40px' }}>
              <SensorTable {...props} sensors={registered} groups={groups} style={{ height: 'calc(100vh - 150px)', width: '100%' }} />
            </ListItem>
          </>
        )}
      </List>
    )
  } else {
    return (
      <Stack spacing={2}>
        <ListSkeleton row_count={4} />
        <ListSkeleton row_count={4} />
      </Stack>
    )
  }
}

export default SensorTab
