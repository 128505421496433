import React, { useState } from 'react'
import { Stack, TextField } from '@mui/material'
import EditModal from './EditModal'
import EditFormButtons from './EditFormButtons'

const def = {
  reason: '',
  notes: ''
}

const EditAlert = ({ onClose, onSubmit, current = def, dismissal = false, title = 'Editing alert', ...rest }) => {
  const [form, setForm] = useState({ reason: current.reason, notes: current.notes })
  const [reasonError, setReasonError] = useState('')

  const onChange = (e) => {
    if (e.target.id === 'reason' && reasonError.length > 0) {
      setReasonError('')
    } else if (e.target.id === 'reason' && e.target.value.length === 0) {
      setReasonError("Reason can't be empty")
    }
    setForm({
      ...form,
      [e.target.id]: e.target.value
    })
  }

  const beforeSubmit = (e) => {
    e.preventDefault()
    let send = {}

    let error = false
    if (form.reason.length === 0) {
      error = true
      setReasonError("Reason can't be empty")
    }
    if (error) {
      return
    }

    if (dismissal) {
      send = {
        ...form,
        alert_id: current.alert_id,
        state: 'acknowledged'
      }
    } else {
      send = form
    }
    onSubmit(e, send)
    onClose()
  }

  return (
    <EditModal title={title} onClose={onClose} {...rest}>
      <form onSubmit={beforeSubmit}>
        <Stack spacing={2}>
          <TextField
            id='reason'
            value={form.reason}
            error={reasonError.length > 0}
            helperText={reasonError}
            onChange={onChange}
            label={'Reason'}
            required={true}
          />
          <TextField id='notes' value={form.notes} onChange={onChange} multiline label='Notes' />
          <EditFormButtons sx={{ paddingBottom: { xs: '20px' } }} onClose={onClose} />
        </Stack>
      </form>
    </EditModal>
  )
}

export default EditAlert
