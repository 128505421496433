import React, { useState } from 'react'
import {
  Button,
  Box,
  Card,
  Stack,
  Typography,
  IconButton,
  InputLabel,
  OutlinedInput,
  FormControl,
  InputAdornment,
  FormHelperText,
  useTheme,
  ListItemButton,
  ListItemText
} from '@mui/material'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { loggedIn } from '../reducers/loginReducer'

import LoopRoundedIcon from '@mui/icons-material/LoopRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import VelocityWarning from '../icons/VelocityWarning'
import NotificationWarning from '../icons/NotificationWarning'
import TemperatureWarning from '../icons/TemperatureWarning'
import VelocityAnomaly from '../icons/VelocityAnomaly'
import TemperatureAnomaly from '../icons/TemperatureAnomaly'
import NotificationAnomaly from '../icons/NotificationAnomaly'
import VelocityError from '../icons/VelocityError'
import TemperatureError from '../icons/TemperatureError'
import NotificationError from '../icons/NotificationError'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

export const AlertIcon = ({ type, direction = 'row', by, smallIcons = false, spacing = 2, amount }) => {
  const theme = useTheme()

  let text, Icon, color

  switch (type) {
    case 'warning':
      text = 'Caution'
      Icon = <FeedbackRoundedIcon sx={{ color: 'warning.main' }} />
      color = theme.palette.warning.main
      switch (by) {
        case 'velocity':
          Icon = <VelocityWarning color={color} />
          break
        case 'temperature':
          Icon = <TemperatureWarning color={color} />
          break
        case 'anomaly':
          Icon = <NotificationWarning color={color} />
          break
        default:
          Icon = `Unknown warning by ${by}`
          break
      }
      break
    case 'anomaly':
      text = 'Anomaly'
      color = theme.palette.anomaly.main
      switch (by) {
        case 'velocity':
          Icon = <VelocityAnomaly color={color} />
          break
        case 'temperature':
          Icon = <TemperatureAnomaly color={color} />
          break
        case 'anomaly':
          Icon = <NotificationAnomaly color={color} />
          break
        default:
          Icon = `Unknown anomaly by ${by}`
      }
      break
    case 'error':
      text = 'Warning'
      color = theme.palette.error.main
      switch (by) {
        case 'velocity':
          Icon = <VelocityError color={color} />
          break
        case 'temperature':
          Icon = <TemperatureError color={color} />
          break
        case 'anomaly':
          Icon = <NotificationError color={color} />
          break
        default:
          Icon = `Unknown error by ${by}`
      }
      break
    case 'normal':
      text = 'Normal'
      Icon = <CheckCircleRoundedIcon color={'success'} />
      break
    case 'mui-error':
      text = 'Error'
      Icon = (
        <div
          style={{
            background: theme.palette.error.main,
            color: theme.palette.alternativeBackground.text,
            padding: '0px 10px 0px 10px',
            borderRadius: '10px'
          }}
        >
          {amount}
        </div>
      )
      break
    default:
      text = 'Unknown'
      break
  }

  return (
    <Stack direction={direction} sx={{ alignItems: 'center' }} spacing={spacing}>
      {Icon}
      <Typography sx={{ fontSize: 'inherit' }} component={Box}>
        {!smallIcons && text}
      </Typography>
    </Stack>
  )
}

AlertIcon.propTypes = {
  variant: PropTypes.oneOf(['warning', 'anomaly', 'error', 'normal', 'mui-error'])
}

export const AlertButton = ({ variant, ...rest }) => {
  let text,
    disabled,
    icon = ''

  if (variant === 'active') {
    text = 'In progress'
    disabled = true
    icon = <LoopRoundedIcon />
  } else if (variant === 'new') {
    text = 'Dismiss'
    disabled = false
    icon = <ClearRoundedIcon />
  } else {
    text = 'Unknown'
    return <></>
  }
  //  LoopRoundedIcon
  // ClearRoundedIcon
  return (
    <>
      <Button sx={{ display: { xs: 'none', md: 'inline-flex' } }} {...rest} disabled={disabled} variant='contained'>
        {text}{' '}
      </Button>
      <IconButton disabled={disabled} sx={{ display: { xs: 'inline-flex', md: 'none' } }} aria-label='text'>
        {icon}
      </IconButton>
    </>
  )
}

export const PrettyDate = ({ date_string, undefinedText }) => {
  if (date_string === undefined) {
    return <> {undefinedText} </>
  }

  if (date_string.length === 0) {
    return <> {undefinedText} </>
  }

  return <>{`${dayjs(date_string).format('L LT')}`}</>
}

export const PrettyDateRange = ({ start, end, variant, typoStyle, ...rest }) => {
  return (
    <Stack direction='row' spacing={1} {...rest}>
      <Typography sx={typoStyle} variant={variant}>
        <PrettyDate date_string={start} />
      </Typography>
      <Typography sx={typoStyle} variant={variant}>
        -
      </Typography>
      <Typography sx={typoStyle} variant={variant}>
        <PrettyDate date_string={end} />
      </Typography>
    </Stack>
  )
}

export const xDaysAgo = (x) => {
  const today = new Date()
  today.setDate(today.getUTCDate() - x)
  return today
}

export const UnderConstruction = ({ sx }) => {
  return (
    <Stack component={Card} spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <ConstructionRoundedIcon color='error' sx={sx} />
      <Typography variant='h5'>Under construction</Typography>
    </Stack>
  )
}

export const PasswordInput = ({
  error,
  helperText,
  onChange,
  value,
  label,
  onEnter = () => {},
  id = 'outlined-adornment-password',
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      onEnter(e)
    }
  }

  return (
    <FormControl variant='outlined' error={error} {...rest}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        value={value}
        error={error}
        onChange={onChange}
        onKeyDown={handleEnter}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      <FormHelperText id='outlined-password-helper-text'>{helperText}</FormHelperText>
    </FormControl>
  )
}

export const SortIcon = ({ down, up, active, onClick }) => {
  const space = '-7px'
  return (
    <Stack onClick={onClick}>
      <ArrowDropUpIcon sx={{ marginBottom: space, visibility: up && active ? 'hidden' : '' }} />
      <ArrowDropDownIcon sx={{ marginTop: space, visibility: down && active ? 'hidden' : '' }} />
    </Stack>
  )
}

export const LogoutButton = () => {
  const dispatch = useDispatch()
  const password = useSelector((state) => state.login.password)

  return (
    <ListItemButton
      sx={{
        visibility: password.length === 0 ? 'hidden' : '',
        color: 'alternativeBackground.text',
        display: 'flex',
        flex: '0 0 auto',
        flexDirection: 'column'
      }}
    >
      <LogoutRoundedIcon onClick={() => dispatch(loggedIn(''))} />
      <ListItemText primary={'Logout'} />
    </ListItemButton>
  )
}
