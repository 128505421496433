import { Box, Button, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

const UploadCard = ({ buttonText, children, loading, disabled, files = [], accept = '.tar', handleFileUpload = () => {} }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
      <div>
        <Button component='label' variant='outlined' disabled={disabled}>
          {buttonText}
          <input type='file' accept={accept} hidden onChange={handleFileUpload} />
        </Button>
      </div>
      {Array.from(files).map((file, index) => (
        <Typography key={`file-${index}`} variant='h8'>
          {file.name}
        </Typography>
      ))}
      {loading && (
        <>
          {' '}
          <CircularProgress />{' '}
        </>
      )}
      {children}
    </Box>
  )
}

export default UploadCard
