import { createSlice } from '@reduxjs/toolkit'

export const splashSlice = createSlice({
  name: 'splash',
  initialState: {
    loading: true
    // Could be replaced with token etc in the future
  },
  reducers: {
    setSplash(_, action) {
      return {
        loading: action.payload
      }
    }
  }
})

export const { setSplash } = splashSlice.actions

export default splashSlice.reducer
