import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton } from '@mui/material'

const ListSkeleton = ({ row_count = 5 }) => {
  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell width={'80%'}>
          <Skeleton variant='text' sx={{ fontSize: '0.875rem' }} width={'100%'} />
        </TableCell>
        <TableCell width={'20%'}>
          <Skeleton variant='text' sx={{ fontSize: '0.875rem' }} width={'100%'} />
        </TableCell>
      </TableRow>
    )
  }

  const rows = [...Array(row_count)].map((_, index) => <SkeletonRow key={index} />)

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Skeleton variant='text' sx={{ fontSize: '0.875rem' }} width={'100%'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListSkeleton
