import React, { useEffect, useState } from 'react'
import { Button, IconButton, Stack, TextField } from '@mui/material'
import EditModal from './EditModal'
import EditFormButtons from './EditFormButtons'
import debug from '../logger'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { useAddGroupMutation, useDeleteGroupMutation, useEditGroupMutation } from '../api/localGwApi'
import useNotify from '../hooks/useNotify'
import DeleteConfirmation from './DeleteConfirmation'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const def = {
  name: '',
  description: ''
}

const EditGroup = ({ group, adding, ...rest }) => {
  const [form, setForm] = useState(def)
  const [open, setOpen] = useState(false)
  const [editGroup] = useEditGroupMutation()
  const [addGroup] = useAddGroupMutation()
  const [onNotify] = useNotify()

  useEffect(() => {
    setForm({
      name: group?.name,
      description: group?.description
    })
  }, [group, open])

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    debug(form)

    const send = Object.entries(form).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})

    if (adding) {
      addGroup(send)
        .unwrap()
        .then((res) => {
          onNotify(res, `Successfully added a new group ${send.name}`)
          onClose()
        })
        .catch((res) => onNotify(res))
    } else {
      // Add id
      send.id = group.id
      editGroup(send)
        .unwrap()
        .then((res) => {
          onNotify(res, `Successfully saved edits on group ${send.name}`)
          onClose()
        })
        .catch((res) => onNotify(res))
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {adding ? (
        <Button variant='contained' onClick={() => setOpen(true)}>
          {' '}
          Add a new group{' '}
        </Button>
      ) : (
        <IconButton onClick={() => setOpen(true)}>
          <EditRoundedIcon sx={{ fill: 'black' }} />
        </IconButton>
      )}
      <EditModal open={open} title={'Editing group'} onClose={onClose} {...rest}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <TextField value={form.name} required id='name' onChange={onChange} label='Name' />
            <TextField value={form.description} id='description' onChange={onChange} label='Description' />
            <EditFormButtons onClose={onClose} />
          </Stack>
        </form>
      </EditModal>
    </>
  )
}

export const DeleteGroup = ({ group }) => {
  const [open, setOpen] = useState(false)
  const [deleteGroup] = useDeleteGroupMutation()
  const [onNotify] = useNotify()

  const onConfirm = (confirm) => {
    if (confirm) {
      deleteGroup(group.id)
        .unwrap()
        .then((res) => {
          onNotify(res, `Successfully deleted group ${group.name}`)
        })
        .catch((res) => {
          onNotify(res)
        })
    }

    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <DeleteRoundedIcon sx={{ fill: 'black' }} />
      </IconButton>
      <DeleteConfirmation title={`Are you sure you wish to delete group ${group.name}`} open={open} onConfirm={onConfirm} />
    </>
  )
}

export default EditGroup
