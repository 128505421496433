import React from 'react'
import { Stack } from '@mui/material'
import FirmwareStepper from './FirmwareStepper'
import NodeFirmwareStepper from './NodeFirmwareStepper'

const FirmwareTab = () => {
  return (
    <Stack spacing={2} sx={{ textAlign: 'center', padding: '2rem' }}>
      <FirmwareStepper />
      <NodeFirmwareStepper />
    </Stack>
  )
}

export default FirmwareTab
