import { Stack, useMediaQuery, Box, LinearProgress } from '@mui/material'
import React from 'react'
import TimkenLogo from '../assests/TimkenLogo'
import { useTheme } from '@emotion/react'
import LegalText from './LegalText'

const SplashScreen = ({ children, showLoading }) => {
  const theme = useTheme()
  const small_screen = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }} spacing={2}>
      <Box sx={{ flexGrow: 1 }}></Box>
      <div>
        <TimkenLogo style={{ maxWidth: small_screen ? '300px' : '' }} fill={theme.palette.primary.main} />
      </div>
      {showLoading && (
        <Box sx={{ width: '200px' }}>
          <LinearProgress color='primary' />
        </Box>
      )}
      {children}
      <Box sx={{ flexGrow: 1 }}></Box>
      <LegalText sx={{ paddingBottom: '20px', fontSize: '9px', width: '80%', maxWidth: '600px' }} />
    </Stack>
  )
}

export default SplashScreen
