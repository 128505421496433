import React, { useState } from 'react'
import { Box, Button, Step, Tooltip, StepLabel, Stepper, Typography } from '@mui/material'
import UploadCard from './UploadCard'
import debug from '../logger'
import { useSendNodeFirmwareMutation } from '../api/localGwApi'
import useNotify from '../hooks/useNotify'

const steps = ['Select node firmware', 'Uploading']

const NodeFirmwareStepper = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [files, setFiles] = useState([])

  const [upload] = useSendNodeFirmwareMutation()
  const [onNotify] = useNotify()

  const handleNext = () => {
    setActiveStep(1)
    uploadNodeFirmware(files[0])
  }

  const handleFileUpload = (e) => {
    debug(e.target.files)
    setFiles(e.target.files)
  }

  const uploadNodeFirmware = (fw) => {
    const data = new FormData()
    data.append('fw_binary', fw)
    upload(data)
      .unwrap()
      .then((res) => {
        console.log(res)
        onNotify({}, 'Upload complete node installation starting...')
        setTimeout(() => {
          window.location.reload(false)
        }, 5000)
      })
      .catch((res) => {
        onNotify(res)
      })
  }

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={`node-step-${index}`}>
              <StepLabel> {label} </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Box>
        <Typography>
          {activeStep === 0 && (
            <>
              Please select a node firmware file from your pc.
              <br /> NOTE you cannot stop the update after the upload has finished.
            </>
          )}
          {activeStep === 1 &&
            'Please wait for the node firmware file to be uploaded. This may take a few minutes depending on your network connection. After the upload you will be moved to the splashscreen where you can see the progress of the update. During this time any other operations are not available and this may take up to 8h.'}
        </Typography>
        {activeStep === 0 && (
          <Tooltip title='Update software image must be available in the device that is used to access the application.'>
            <span>
              <UploadCard buttonText={'Select node firmware'} files={files} handleFileUpload={handleFileUpload} accept='.tar.gz' />
            </span>
          </Tooltip>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', pt: 2 }}>
          <Button onClick={handleNext} disabled={activeStep !== 0 || files.length === 0}>
            {activeStep === 0 && 'Start installation'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default NodeFirmwareStepper
