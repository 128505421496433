import { InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

const NumberInput = (props) => {
  const { onChange, value, unit, ...rest } = props

  const [number, setNumber] = useState('')

  useEffect(() => {
    setNumber(value)
  }, [value])

  const onNumberChange = (e) => {
    const number = parseFloat(e.target.value)
    if (number || e.target.value === '' || e.target.value === '0') {
      setNumber(e.target.value)
      onChange(e)
    }
  }

  return (
    <TextField
      InputProps={{ endAdornment: <InputAdornment position='end'>{unit}</InputAdornment> }}
      onChange={onNumberChange}
      value={number}
      type='number'
      {...rest}
    />
  )
}

NumberInput.defaultProps = {
  onChange: () => {}
}

export default NumberInput
