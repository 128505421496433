import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded'
import { AlertIcon } from './Elements'

const section_style = {
  display: 'flex',
  direction: 'row',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center'
}

const icon_style = {
  height: '100%',
  width: '100%',
  padding: { xs: '10%', md: '25%', lg: '15%' },
  color: 'white'
}

const NumberTypo = styled(Typography)((props) => ({
  fontSize: '1.3rem',
  [props.theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem'
  },
  [props.theme.breakpoints.up('md')]: {
    fontSize: '1.8rem'
  }
}))

const TextTypo = styled(Typography)((props) => ({
  fontSize: '0.8rem',
  [props.theme.breakpoints.up('sm')]: {
    fontSize: '1.0rem'
  },
  [props.theme.breakpoints.up('md')]: {
    fontSize: '1.3rem'
  }
}))

const DashBoardCard = ({ variant, values }) => {
  const Content = () => {
    switch (variant) {
      case 'alert_warning':
        return (
          <>
            <Grid item xs={4} sx={{ ...section_style, backgroundColor: 'grey.100' }}>
              <Tooltip title='Number of new or active vibration and temperature warnings.'>
                <Stack sx={{ color: 'error.main', textAlign: 'center' }}>
                  <NumberTypo>{values[0]}</NumberTypo>
                  <TextTypo component={Box} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <AlertIcon direction='column' type='error' by='anomaly' spacing={'4px'} />
                  </TextTypo>
                </Stack>
              </Tooltip>
            </Grid>
            <Grid item xs={4} sx={{ ...section_style, backgroundColor: 'grey.100' }}>
              <Tooltip title='Number of new or active vibration and temperature cautions.'>
                <Stack sx={{ color: 'warning.light', textAlign: 'center' }}>
                  <NumberTypo>{values[1]}</NumberTypo>
                  <TextTypo component={Box}>
                    <AlertIcon direction='column' type='warning' by='anomaly' spacing={'4px'} />
                  </TextTypo>
                </Stack>
              </Tooltip>
            </Grid>
          </>
        )
      case 'anomaly': {
        return (
          <Grid item xs={8} sx={{ ...section_style, backgroundColor: 'grey.100' }}>
            <Tooltip title='Number of new or active vibration anomalies.'>
              <Stack sx={{ color: 'success.main', textAlign: 'center' }}>
                <NumberTypo>{values[0]}</NumberTypo>
                <TextTypo>Anomalies</TextTypo>
              </Stack>
            </Tooltip>
          </Grid>
        )
      }
      case 'sensors': {
        const amount = values[2] === undefined ? '' : values[2]
        return (
          <>
            <Grid item xs={4} sx={{ ...section_style, backgroundColor: 'grey.100' }}>
              <Tooltip title='Number of registered sensors that have communicated with the Gateway in the last 3 hours and are considered online.'>
                <Stack sx={{ color: 'success.main', textAlign: 'center', marginLeft: '5px' }}>
                  <NumberTypo>{`${values[0]}/${amount}`}</NumberTypo>
                  <TextTypo sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '1.2' }}>
                    Sensors online
                  </TextTypo>
                </Stack>
              </Tooltip>
            </Grid>
            <Grid item xs={4} sx={{ ...section_style, backgroundColor: 'grey.100' }}>
              <Tooltip title='Number of registered sensors that have communicated a low battery alert. Such a device will require replacement soon.'>
                <Stack sx={{ color: 'success.main', textAlign: 'center' }}>
                  <NumberTypo>{`${values[1]}/${amount}`}</NumberTypo>
                  <TextTypo sx={{ lineHeight: '1.2' }}>Low Battery</TextTypo>
                </Stack>
              </Tooltip>
            </Grid>
          </>
        )
      }
      default:
        return (
          <Grid item xs={8} sx={{ ...section_style, backgroundColor: 'grey.100' }}>
            Unknown variant
          </Grid>
        )
    }
  }

  const Icon = () => {
    switch (variant) {
      case 'alert_warning': {
        return <NotificationsActiveRoundedIcon sx={icon_style} />
      }
      case 'anomaly': {
        return <SearchRoundedIcon sx={icon_style} />
      }
      case 'sensors': {
        return <SensorsRoundedIcon sx={icon_style} />
      }
      default:
        return <div> unknown variant</div>
    }
  }

  return (
    <Box
      sx={{
        minHeight: { xs: 50, sm: 100 },
        minWidth: { xs: 50, sm: 100 },
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        maxHeight: { xs: 80, sm: 100, md: 160 },
        maxWidth: { xs: 600, sm: 900, md: 900 }
      }}
    >
      <Stack direction='row' sx={{ display: 'flex', flex: '1 0 auto', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={4} sx={{ ...section_style, backgroundColor: '#dddddd' }}>
            <Icon />
          </Grid>
          <Content />
        </Grid>
      </Stack>
    </Box>
  )
}

export default DashBoardCard
