import React, { useState } from 'react'
import { useGetAlertsQuery } from '../api/localGwApi'
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import AlertList from './AlertList'
import ListSkeleton from './ListSkeleton'
import DateSelector from './DateSelector'
import { PrettyDateRange, xDaysAgo } from './Elements'

const AlertTab = () => {
  const [historyRange, setHistoryRange] = useState({
    startDate: xDaysAgo(90),
    endDate: new Date()
  })
  const { data: alerts, isLoading } = useGetAlertsQuery({
    states: ['new', 'active']
  })
  const { data: historyAlerts, isLoading: historyLoading } = useGetAlertsQuery({
    states: ['acknowledged'],
    from_date: historyRange?.startDate?.toISOString(),
    to_date: historyRange?.endDate?.toISOString()
  })

  const [value, setValue] = useState(0)
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const new_active_alerts = alerts?.filter((al) => al.state === 'new' || al.state === 'active')

  if (isLoading || alerts === undefined) {
    return <ListSkeleton row_count={5} />
  } else {
    return (
      <Stack height='calc(100% - 80px)' style={{ position: 'relative' }}>
        <Stack
          sx={{
            flexDirection: { xs: 'column', lg: 'row' },
            marginBottom: { xs: '20px' }
          }}
          justifyContent='space-between'
        >
          <Tabs sx={{ paddingBottom: '1rem' }} value={value} onChange={handleChange}>
            <Tab label='Active Alerts' />
            <Tab label='History' />
          </Tabs>
          {value === 1 && (
            <Stack spacing={1} sx={{ right: 0, position: { xs: 'static', md: 'absolute' } }}>
              <div style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                <DateSelector onChange={(e) => setHistoryRange(e)} />
              </div>
              {historyRange.startDate !== undefined && historyRange.endDate !== undefined && (
                <PrettyDateRange style={{ justifyContent: 'flex-end' }} start={historyRange?.startDate} end={historyRange?.endDate} />
              )}
            </Stack>
          )}
        </Stack>
        <Box height={'100%'}>
          <TabPanel value={value} index={0} style={{ height: '100%' }}>
            {isLoading || alerts === undefined ? (
              <ListSkeleton row_count={5} />
            ) : (
              <>
                {new_active_alerts.length === 0 ? (
                  <Typography variant='h6'>NO ALERTS!</Typography>
                ) : (
                  <AlertList style={{ height: '100%' }} alerts={alerts} showNew showActive dismiss />
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} style={{ height: '100%' }}>
            {historyLoading || historyAlerts === undefined ? (
              <ListSkeleton row_count={5} />
            ) : (
              <>
                {historyAlerts.length === 0 ? (
                  <Stack>
                    <Typography variant='h6'>NO ALERTS FOUND</Typography>
                  </Stack>
                ) : (
                  <AlertList style={{ height: '100%' }} alerts={historyAlerts} showAcknowledged />
                )}
              </>
            )}
          </TabPanel>
        </Box>
      </Stack>
    )
  }
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

export default AlertTab
