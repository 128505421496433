import * as React from 'react'
const NotificationWarning = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 24
    }}
    {...props}
  >
    <path
      fill={color}
      d='M0 2.158C0 .966.966 0 2.158 0h19.684C23.034 0 24 .966 24 2.158v19.684A2.158 2.158 0 0 1 21.842 24H2.158A2.158 2.158 0 0 1 0 21.842V2.158Z'
    />
    <path
      fill='#fff'
      d='M12.192 4.058h-.336c-.826 0-1.496.67-1.496 1.496v7.152c0 .826.67 1.496 1.496 1.496h.336c.826 0 1.496-.67 1.496-1.496V5.554c0-.827-.67-1.496-1.496-1.496ZM12.024 19.874a1.664 1.664 0 1 0 0-3.328 1.664 1.664 0 0 0 0 3.328Z'
    />
  </svg>
)
export default NotificationWarning
