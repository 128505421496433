import { Dialog, Stack, useMediaQuery, useTheme, Container, Typography, IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import React from 'react'

const EditModal = ({ children, title, onClose, ...rest }) => {
  const theme = useTheme()
  const small_screen = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Dialog fullScreen={small_screen} onClose={onClose} style={{ width: '100%' }} {...rest}>
      <Container sx={{ p: 4, height: { xs: '100%' } }}>
        <IconButton style={{ position: 'absolute', right: 20, top: 20 }} onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
        <Stack spacing={2} sx={{ height: { xs: '100%' }, justifyContent: 'space-between' }}>
          <Typography variant='h6' component='h6'>
            {' '}
            {title}{' '}
          </Typography>
          {children}
        </Stack>
      </Container>
    </Dialog>
  )
}

export default EditModal
