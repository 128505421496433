import React from 'react'
import debug from '../logger'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import EditGroup, { DeleteGroup } from './EditGroup'
import { StyledCell } from './VirtualList'

const GroupTable = ({ groups, ...rest }) => {
  debug('Groups', groups)
  return (
    <TableContainer {...rest}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledCell sx={{ color: 'alternativeBackground.text', backgroundColor: 'primary.main' }}> Name </StyledCell>
            <StyledCell sx={{ color: 'alternativeBackground.text', backgroundColor: 'primary.main' }}> Description </StyledCell>
            <StyledCell sx={{ color: 'alternativeBackground.text', backgroundColor: 'primary.main' }}> Edit </StyledCell>
            <StyledCell sx={{ color: 'alternativeBackground.text', backgroundColor: 'primary.main' }}> Delete </StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group) => (
            <TableRow key={group.id}>
              <StyledCell>{group.name}</StyledCell>
              <StyledCell>{group.description}</StyledCell>
              <StyledCell>
                <EditGroup group={group} />
              </StyledCell>
              <StyledCell>
                <DeleteGroup group={group} />
              </StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default GroupTable
