import * as React from 'react'
const TemperatureWarning = ({ props, style, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 24
    }}
    {...props}
  >
    <path
      fill={color}
      d='M0 2.158C0 .966.966 0 2.158 0h19.684C23.034 0 24 .966 24 2.158v19.684A2.158 2.158 0 0 1 21.842 24H2.158A2.158 2.158 0 0 1 0 21.842V2.158Z'
    />
    <path fill='#fff' d='M12.554 14.308V10.89a.553.553 0 1 0-1.106 0v3.417a1.658 1.658 0 1 0 1.106 0Z' />
    <path
      fill='#fff'
      d='M14.765 13.164V7.022a2.764 2.764 0 0 0-5.528 0v6.142a3.865 3.865 0 0 0 2.759 6.572 3.866 3.866 0 0 0 2.77-6.572Zm-1.017 4.842a2.76 2.76 0 0 1-3.593-4.19.542.542 0 0 0 .188-.415V7.022a1.658 1.658 0 0 1 3.317 0v6.38a.543.543 0 0 0 .188.414 2.754 2.754 0 0 1-.1 4.19Z'
    />
  </svg>
)
export default TemperatureWarning
