import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    password: ''
    // Could be replaced with token etc in the future
  },
  reducers: {
    loggedIn: (_, action) => {
      return {
        password: action.payload
      }
    }
  }
})

export const { loggedIn } = loginSlice.actions

export default loginSlice.reducer
