import React, { useState } from 'react'
import { Button, Stack, Dialog, useMediaQuery, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { rangeChanged } from '../reducers/timeSeriesReducer'
import { DateRange, DateRangePicker, defaultStaticRanges } from 'react-date-range'
import { useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { xDaysAgo } from './Elements'
import debug from '../logger'

export const DateSelectorForSeries = () => {
  const range_object = useSelector((state) => state.series.range)

  const dispatch = useDispatch()

  const onApply = (value) => {
    dispatch(
      rangeChanged({
        from: value.startDate.toISOString(),
        to: value.endDate.toISOString()
      })
    )
  }

  return <DateSelector initialStart={new Date(range_object.from)} initialEnd={new Date(range_object.to)} onChange={onApply} />
}

function DateSelector({ children, buttonText = 'Select date range', ...rest }) {
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack>
      <Button variant='contained' onClick={() => setOpen(true)}>
        {' '}
        {buttonText}{' '}
      </Button>
      {children}
      <Dialog onClose={onClose} fullScreen={sm} open={open}>
        <StandaloneDateSelector onClose={onClose} {...rest} />
      </Dialog>
    </Stack>
  )
}

export function StandaloneDateSelector({
  loading = false,
  disableButton = false,
  buttonText = 'Apply',
  initialEnd = xDaysAgo(1),
  initialStart = new Date(),
  onChange = () => {},
  onClose = () => {}
}) {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const [selection, setSelection] = useState({
    startDate: initialStart,
    endDate: initialEnd,
    key: 'selection'
  })

  const onSave = () => {
    onChange(selection)
    onClose()
  }

  return (
    <Stack sx={{ justifyContent: { xs: 'space-between' }, height: { xs: '100%' } }}>
      {sm ? (
        <DateRange rangeColors={[theme.palette.primary.main]} ranges={[selection]} onChange={(ranges) => setSelection(ranges.selection)} />
      ) : (
        <DateRangePicker
          rangeColors={[theme.palette.primary.main]}
          ranges={[selection]}
          onChange={(ranges) => {
            debug(ranges)
            setSelection(ranges.selection)
          }}
          staticRanges={[
            {
              label: 'Last 12h',
              range: () => {
                return {
                  startDate: new Date(date12hAgo(new Date())),
                  endDate: new Date()
                }
              },
              isSelected(range) {
                const definedRange = this.range()
                return (
                  dayjs(range.startDate).isSame(dayjs(definedRange.startDate), 'hour') &&
                  dayjs(range.endDate).isSame(dayjs(definedRange.endDate), 'hour')
                )
              }
            },
            ...defaultStaticRanges
          ]}
        />
      )}
      <Stack direction='row-reverse' padding={1} sx={{ paddingBottom: { xs: '20px' } }}>
        <Button disabled={disableButton} onClick={onSave} variant='contained'>
          {buttonText}
          {loading && <CircularProgress sx={{ color: 'white' }} size='20px' />}
        </Button>
      </Stack>
    </Stack>
  )
}

function date12hAgo(date) {
  return date.setTime(date.getTime() - 12 * 60 * 60 * 1000)
}

export default DateSelector
