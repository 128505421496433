import React, { useState } from 'react'
import { Box, Button, FormControl, Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import TimeSeriesChart from './TimeSeriesChart'
import { PrettyDate, PrettyDateRange } from './Elements'
import { DateSelectorForSeries } from './DateSelector'
import { acceleration, temperature, velocity } from './Units'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'

const TimeSeriesView = ({ sensor, alerts, si, time_series, loading, onRefresh }) => {
  const charts = [
    {
      title: `Velocity rms ${velocity(si)}`,
      velocity_limits: true,
      series: {
        'Velocity rms (x)': 'vel_rms_x',
        'Velocity rms (y)': 'vel_rms_y',
        'Velocity rms (z)': 'vel_rms_z'
      }
    },
    {
      title: `Velocity p2p ${velocity(si)}`,
      velocity_limits: true,
      series: {
        'Velocity p2p (x)': 'vel_p2p_x',
        'Velocity p2p (y)': 'vel_p2p_y',
        'Velocity p2p (z)': 'vel_p2p_z'
      }
    },
    {
      title: `Velocity z2p ${velocity(si)}`,
      velocity_limits: true,
      series: {
        'Velocity z2p (x)': 'vel_z2p_x',
        'Velocity z2p (y)': 'vel_z2p_y',
        'Velocity z2p (z)': 'vel_z2p_z'
      }
    },
    {
      title: `Acceleration rms ${acceleration(si)}`,
      series: {
        'Acceleration rms (x)': 'acc_rms_x',
        'Acceleration rms (y)': 'acc_rms_y',
        'Acceleration rms (z)': 'acc_rms_z'
      }
    },
    {
      title: `Acceleration p2p ${acceleration(si)}`,
      series: {
        'Acceleration p2p (x)': 'acc_p2p_x',
        'Acceleration p2p (y)': 'acc_p2p_y',
        'Acceleration p2p (z)': 'acc_p2p_z'
      }
    },
    {
      title: `Acceleration z2p ${acceleration(si)}`,
      series: {
        'Acceleration z2p (x)': 'acc_z2p_x',
        'Acceleration z2p (y)': 'acc_z2p_y',
        'Acceleration z2p (z)': 'acc_z2p_z'
      }
    },
    {
      title: 'Crest factor',
      series: {
        'Crest factor (x)': 'crest_x',
        'Crest factor (y)': 'crest_y',
        'Crest factor (z)': 'crest_z'
      }
    },
    {
      title: 'Kurtosis',
      series: {
        'Kurtosis (x)': 'kurtosis_x',
        'Kurtosis (y)': 'kurtosis_y',
        'Kurtosis (z)': 'kurtosis_z'
      }
    }
  ]
  const range_object = useSelector((state) => state.series.range)
  const [currentSeries, setCurrentSeries] = useState(0)
  const [annotations] = useState(true)

  const SelectedChart = ({ chart }) => {
    let ser = {}
    let limits = []
    for (const [key, value] of Object.entries(chart.series)) {
      ser = {
        ...ser,
        [key]: time_series[value] === undefined ? [] : time_series[value]
      }
    }

    if (chart.velocity_limits) {
      limits = [0, 0]
      if (sensor.velocity_warning) {
        limits[0] = sensor.velocity_warning
      }
      if (sensor.velocity_error) {
        limits[1] = sensor.velocity_error
      }
    }

    return (
      <Stack>
        <Stack sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} direction='row' justifyContent='space-between' spacing={2}>
          <FormControl>
            <Select
              renderValue={(val) => {
                return (
                  <Typography variant='h6' fontWeight='bold'>
                    {charts[val].title}
                  </Typography>
                )
              }}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '.MuiSelect-select': {
                  paddingLeft: 0
                }
              }}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
              value={currentSeries}
              onChange={(e) => setCurrentSeries(Number(e.target.value))}
            >
              {charts.map((chart, index) => {
                return (
                  <MenuItem key={`series-${index}`} value={index}>
                    {chart.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
        <TimeSeriesChart
          yTitle=''
          series={ser}
          si={si}
          limits={limits}
          time={time_series.timestamp}
          alerts={alerts}
          enableAnnotations={annotations}
          by={['velocity', 'annotations']}
        />
      </Stack>
    )
  }

  return (
    <Box sx={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }} component='div'>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: '0px',
          paddingRight: '16px',
          marginLeft: 0,
          width: 'inherit',
          overflow: 'hidden'
        }}
      >
        {sensor === undefined ? (
          <Grid item xs={12} sx={{ typography: 'h6' }}>
            Unknown sensor ID provided.
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6} lg={6} style={{ paddingTop: 0 }} sx={{ typography: 'h6' }}>
              <Stack spacing={0.5}>
                <Typography component={Box} variant='h6'>
                  <Box fontWeight='bold' display='inline'>
                    {' '}
                    Sensor name:{' '}
                  </Box>{' '}
                  {sensor.name}
                </Typography>
                <Typography component={Box}>
                  <Box fontWeight='bold' display='inline'>
                    {' '}
                    ID:{' '}
                  </Box>
                  {sensor.serial}
                </Typography>
                <Typography component={Box}>
                  <Box fontWeight='bold' display='inline'>
                    {' '}
                    Last updated:{' '}
                  </Box>{' '}
                  <PrettyDate date_string={sensor.last_message} />
                </Typography>
              </Stack>
            </Grid>
            {/*<Grid item sx={{ display: { md: 'flex', lg: 'none' } }}></Grid>
             */}
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              style={{ paddingTop: 0 }}
              sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, paddingTop: 0 }}
            >
              <Stack spacing={2} direction={'row'}>
                <Stack spacing={2} sx={{ width: { xs: '100%' }, maxWidth: { xs: '100%', md: '400px' } }}>
                  <Button variant='contained' onClick={onRefresh}>
                    Refresh
                  </Button>
                  <DateSelectorForSeries />
                  {/*<Button variant='contained' onClick={() => setAnnotations(!annotations)}>
                    {annotations ? <>Disable annotations</> : <>Enable annotations</>}
                  </Button>
                  */}
                  <PrettyDateRange
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    start={range_object.from}
                    end={range_object.to}
                  />
                </Stack>
                {/*
                <Stack spacing={2} sx={{ width: { xs: '100%' }, maxWidth: { xs: '100%', md: '300px' } }}>
                  <WaveFormButton serial={sensor.serial} />
                </Stack>
                     * */}
              </Stack>
            </Grid>
            <Grid item container xs={12}>
              {!loading ? (
                <Stack spacing={4} sx={{ width: 'inherit' }}>
                  <SelectedChart chart={charts[currentSeries]} />

                  <TimeSeriesChart
                    yTitle=''
                    si={si}
                    title={`Temperature ${temperature(si)}`}
                    filterNull
                    alerts={alerts}
                    series={{
                      Temperature: time_series.temperature
                    }}
                    limits={[
                      sensor.temp_warning === undefined ? 0 : sensor.temp_warning,
                      sensor.temp_error === undefined ? 0 : sensor.temp_error
                    ]}
                    time={time_series.timestamp}
                    enableAnnotations={annotations}
                    by={['temperature']}
                  />
                  <TimeSeriesChart
                    si={si}
                    yTitle={''}
                    title={
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <div> {'Battery voltage mV'} </div>
                        <Typography variant='h6'>
                          <Tooltip title='If the battery voltage remains under critical level, the device will require replacement soon.'>
                            <HelpOutlineRoundedIcon />
                          </Tooltip>
                        </Typography>
                      </Stack>
                    }
                    series={{
                      'Battery voltage': time_series.battery_voltage_mv
                    }}
                    time={time_series.timestamp}
                    filterNull
                    enableAnnotations={annotations}
                    min={2000}
                    max={3700}
                    red_area={3100}
                  />
                </Stack>
              ) : (
                <div>Loading...</div>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

/*
 * FIXME: Consider removing
 * -- Remove was requested. Leaving here for now in case some one wants it back.
function WaveFormButton({ serial }) {
  const [startRawData] = useStartRawDataMutation({})
  const [onNotify] = useNotify()

  const { data: measurement_state } = useWaveformStatusQuery({ serial: serial })
  const [updateStatus] = useLazyWaveformStatusQuery({ serial: serial })
  const [getLatestMeasurement] = useLazyGetWaveformQuery()

  const saveFile = async (blob) => {
    debug(blob)
    const a = document.createElement('a')
    a.download = `waveform-${serial}.csv`
    a.href = URL.createObjectURL(blob)
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
    })
    a.click()
  }

  const onClick = (e) => {
    debug('WaveFormButton', e)
    startRawData(serial)
      .unwrap()
      .then((res) => {
        debug('Start request', res)
        pollRawData(serial)
          .then((res) => {
            debug('PollRawData', res)
            onNotify({}, `Measurement for node ${serial} ready`)
            updateStatus({ serial: serial })
          })
          .catch((err) => {
            debug('WaveFormButton', err)
            onNotify(err)
            updateStatus({ serial: serial })
          })
      })
      .catch((res) => {
        onNotify(res)
      })
    updateStatus({ serial: serial })
    return
  }

  const onDownload = () => {
    getLatestMeasurement({ serial: serial })
      .unwrap()
      .then((res) => {
        debug('Get latest', res)
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
        saveFile(blob)
      })
      .catch((res) => onNotify(res))
  }

  return (
    <Stack spacing={2}>
      <Tooltip title='Initiates a vibration measurement and receives a waveform from the sensor.'>
        <span style={{ width: '100%' }}>
          <Button
            variant='contained'
            disabled={measurement_state === 'measuring' || measurement_state === undefined}
            onClick={onClick}
            sx={{ width: '100%' }}
          >
            Measure raw data
            {measurement_state === 'measuring' && <CircularProgress sx={{ color: 'white' }} size='20px' />}
          </Button>
        </span>
      </Tooltip>
      <Tooltip title='Download the measured waveform from the Gateway as a CSV-file.'>
        <span style={{ width: '100%' }}>
          <Button
            variant='contained'
            disabled={measurement_state === 'measuring' || measurement_state === 'idle' || measurement_state === undefined}
            onClick={onDownload}
            sx={{ width: '100%' }}
          >
            Download latest measurement
          </Button>
        </span>
      </Tooltip>
    </Stack>
  )
}
*/

export default TimeSeriesView
