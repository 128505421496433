import * as React from 'react'
const TemperatureAnomaly = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 24
    }}
    {...props}
  >
    <path fill={color} d='M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z' />
    <path fill='#fff' d='M12.52 14.171v-3.21a.52.52 0 1 0-1.038 0v3.21a1.559 1.559 0 1 0 1.039 0Z' />
    <path
      fill='#fff'
      d='M14.598 13.096V7.325a2.597 2.597 0 1 0-5.193 0v5.771a3.633 3.633 0 0 0 2.591 6.177 3.632 3.632 0 0 0 2.602-6.177Zm-.956 4.55a2.594 2.594 0 0 1-3.375-3.938.51.51 0 0 0 .176-.388V7.324a1.558 1.558 0 0 1 3.116 0v5.994a.51.51 0 0 0 .177.39 2.59 2.59 0 0 1-.094 3.938Z'
    />
  </svg>
)
export default TemperatureAnomaly
