import { Box, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useGetTimeQuery } from '../api/localGwApi'
import { PrettyDate } from './Elements'
import dayjs from 'dayjs'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'

const Clock = (props) => {
  const { data, error } = useGetTimeQuery(
    {},
    {
      pollingInterval: 30000
    }
  )

  if (error) {
    console.log(error)
  }

  const date = dayjs(data)
  const current_time = dayjs()

  const diff = current_time.diff(date, 'hour')

  const wrong_time = diff > 2 || -diff > 2

  return (
    <Box {...props}>
      <Tooltip
        title={
          wrong_time
            ? 'The current gateway time is off by more than 2 hours. Please go to admin → settings to fix this.'
            : 'The current gateway time.'
        }
      >
        <Stack direction='row' spacing={1} justifyContent='center'>
          <Typography fontWeight='bold'>Gateway time</Typography>
          <Typography>-</Typography>
          <Typography>
            <PrettyDate date_string={data} />
          </Typography>
          {wrong_time && <ErrorRoundedIcon color={'error'} />}
        </Stack>
      </Tooltip>
    </Box>
  )
}

export default Clock
