import * as React from 'react'
const TimkenLogo = ({ fill, style, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='timken-logo_svg__svg4224'
    width={519.643}
    height={111.258}
    viewBox='0 0 487.166 104.305'
    {...rest}
    style={style}
  >
    <style xmlns='http://www.w3.org/1999/xhtml' id='VMst0.5057925227088534'>
      {'.timken-logo_svg__vimvixen-console-frame{height:0;color-scheme:light!important}'}
    </style>
    <g id='timken-logo_svg__layer1' transform='translate(-87.543 -614.495)'>
      <path
        id='timken-logo_svg__path78'
        d='M103.593 718.086v-11.777s9.99.171 9.99-7.96v-72.477h-9.99s-5.988.2-5.988 6.86v9.705H87.543v-27.941h72.733v27.94h-11.105v-9.432s1.872-6.732-6.732-6.732h-8.875v71.633s-.2 8.404 10.619 8.404v11.777h-40.59zm0 0zm102.704.714h-40.39v-11.934s10.262-.085 10.262-7.017V633.56s-.486-7.575-9.433-7.575v-11.49h40.104v11.776s-10.619-1.872-10.619 6.175v67.13s1.186 6.46 9.79 6.46c0 5.26.286 12.763.286 12.763zm0 0zm42.605 0h-31.986V706.31s9.433-.357 9.433-10.62v-63.242s-.071-6.46-12.291-6.46v-11.491h34.844l18.666 60.584 18.394-60.584h35.96v12.32s-12.22-1.301-12.22 7.574v62.129s-1.187 8.404 8.06 8.404v13.163h-36.788v-12.892s8.118.486 8.118-8.404v-48.45l-19.58 69.746h-8.876l-20.052-70.375v49.365s-.086 8.118 8.318 8.118V718.8zm0 0zm80.308-.286v-13.863s10.005 2.415 10.005-5.631v-66.845s-2.144-6.46-10.005-6.46c.272-3.888 0-11.22 0-11.22h40.962v10.934s-9.79.086-9.79 7.575v24.768l18.394-25.883s.829-5.903-3.059-5.903v-11.49h33.73v11.776s-6.374.744-8.59 3.602c-2.23 2.858-20.895 29.013-20.895 29.013l25.054 42.906s3.33 3.959 6.375 3.959v12.334h-35.945V704.65s10.419 2.415 4.516-6.474c-5.903-8.876-20.41-30.6-20.41-30.6v30.885s-1.114 7.29 9.505 7.29v12.334l-39.847.428zm0 0zm87.255 0v-13.591s10.547 2.415 10.547-6.46v-65.459s-1.315-6.732-10.547-6.732v-11.777h66.273v25.727h-12.763v-7.49s-.63-5.917-6.732-5.917h-16.45v31.515h10.347s5.274.086 5.274-9.99h10.062v32h-10.348s.358-11.377-6.102-11.377h-9.505v34.845h18.666s5.631.843 5.631-11.177h11.648v25.455l-66.001.428zm0 0zm74.962 0v-13.591s9.162 2.215 9.162-9.233v-61.014s.9-8.69-9.719-8.69v-11.49h33.372s24.297 65.3 24.297 64.458v-49.08s-3.673-4.16-10.89-4.16v-11.219h37.06v11.22s-11.377.629-11.377 6.174v86.197h-20.967l-26.998-70.104v46.879s.357 10.062 9.519 10.062v13.163l-33.459.428z'
        style={{
          fill: fill,
          stroke: 'none'
        }}
      />
    </g>
  </svg>
)
export default TimkenLogo
