import * as React from 'react'
const VelocityError = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 -1.5 24 22'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 22
    }}
    {...props}
  >
    <path
      fill={color}
      d='M10.227 1.024.274 18.262a2.048 2.048 0 0 0 1.774 3.071h19.904a2.048 2.048 0 0 0 1.773-3.071L13.774 1.024a2.048 2.048 0 0 0-3.546 0Z'
    />
    <path
      fill='#fff'
      d='M16.82 11.224c-.296.215-.534.5-.695.83-.237.395-.371.593-.58.593-.21 0-.345-.19-.582-.593-.237-.403-.577-.988-1.264-.988-.688 0-1.02.569-1.265.988-.245.419-.371.593-.577.593-.206 0-.344-.19-.581-.593-.237-.403-.577-.988-1.265-.988-.687 0-1.02.569-1.264.988-.245.419-.368.593-.581.593-.214 0-.344-.19-.577-.593-.16-.33-.4-.615-.696-.83a.396.396 0 0 0-.403.68c.176.15.318.335.415.545.24.42.573.988 1.26.988.688 0 1.024-.569 1.265-.988.241-.419.368-.593.581-.593.214 0 .344.19.577.593.234.403.577.988 1.269.988s1.02-.569 1.265-.988c.245-.419.367-.593.58-.593.214 0 .345.19.582.593.237.403.577.988 1.264.988.688 0 1.02-.569 1.265-.988a1.58 1.58 0 0 1 .395-.545.396.396 0 0 0 .182-.34.396.396 0 0 0-.57-.34ZM16.82 15.176c-.296.216-.534.5-.695.83-.237.395-.371.593-.58.593-.21 0-.345-.19-.582-.593-.237-.403-.577-.988-1.264-.988-.688 0-1.02.57-1.265.988-.245.42-.371.593-.577.593-.206 0-.344-.19-.581-.593-.237-.403-.577-.988-1.265-.988-.687 0-1.02.57-1.264.988-.245.42-.368.593-.581.593-.214 0-.344-.19-.577-.593-.16-.33-.4-.614-.696-.83a.394.394 0 0 0-.57.34.395.395 0 0 0 .167.34c.176.15.318.336.415.545.24.42.573.989 1.26.989.688 0 1.024-.57 1.265-.989.241-.418.368-.592.581-.592.214 0 .344.19.577.592.234.404.577.989 1.269.989s1.02-.57 1.265-.989c.245-.418.367-.592.58-.592.214 0 .345.19.582.592.237.404.577.989 1.264.989.688 0 1.02-.57 1.265-.989.091-.208.226-.394.395-.545a.394.394 0 0 0 .111-.52.396.396 0 0 0-.498-.16Z'
    />
  </svg>
)
export default VelocityError
