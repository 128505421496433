import './App.css'
import React from 'react'
import { Box, Stack } from '@mui/system'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getUpdateStatus, pollVersion } from './api/localGwApi'
import { Button, Typography } from '@mui/material'

// Tabs
import DashboardTab from './components/DashboardTab'
import SideBar from './components/SideBar'
import TimeSeriesTab from './components/TimeSeriesTab'
import MobileSeries from './components/MobileSeries'
import SplashScreen from './components/SplashScreen'
import AlertTab from './components/AlertTab'
import AdminTab from './components/AdminTab'
import useNotify from './hooks/useNotify'
import { useDispatch, useSelector } from 'react-redux'
import { setSplash } from './reducers/splashReducer'

const App = () => {
  const [version, setVersion] = useState()

  const dispatch = useDispatch()
  const showLoading = useSelector(({ splash }) => splash.loading)

  const setShowLoading = (val) => {
    dispatch(setSplash(val))
  }
  const [error, setError] = useState('')
  const [updateContent, setUpdateContent] = useState(undefined)
  const [onNotify] = useNotify()

  useEffect(() => {
    // Start polling if show loading is to true
    if (!showLoading) {
      return
    }
    pollVersion()
      .then((res) => {
        setVersion(JSON.parse(res))
        getUpdateStatus()
          .then((res) => {
            if (res.status === 200 || res.status === 301) {
              res.json().then((res) => {
                setUpdateContent(res)
              })
            } else {
              console.error(res)
              setShowLoading(false)
            }
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch(() => {
        onNotify({ error: { data: 'Failed to connect to gateway' } })
        setError('Failed to connect to gateway')
      })
    // Adding onNotify causes this to be called too often
    // eslint-disable-next-line
  }, [showLoading])

  useEffect(() => {
    if (updateContent !== undefined && showLoading) {
      setTimeout(() => {
        getUpdateStatus()
          .then((res) => {
            if (res.status === 200) {
              res.json().then((res) => {
                setUpdateContent(res)
              })
            } else if (res.status === 301) {
              setShowLoading(false)
            } else {
              console.error(res)
              onNotify({ error: {} })
              setError('Something went wrong.')
            }
          })
          .catch((err) => onNotify(err))
      }, 10000)
    }
  }, [updateContent, onNotify, showLoading])

  const onRetry = () => {
    setError(undefined)
    pollVersion()
      .then((res) => {
        setVersion(JSON.parse(res))
      })
      .catch(() => {
        onNotify({ error: { data: 'Failed to connect to gateway' } })
        setError('Failed to connect to gateway')
      })
  }

  const onUpdateFinnish = () => {
    setShowLoading(true)
    setUpdateContent(undefined)
    setError('')
    onRetry()
  }

  if (showLoading || updateContent) {
    return (
      <SplashScreen showLoading={showLoading}>
        <Box>{error}</Box>
        {error?.length > 0 && (
          <Box>
            <Button onClick={onRetry} variant='contained'>
              Retry
            </Button>
          </Box>
        )}
        {updateContent !== undefined ? (
          <Stack direction='column' sx={{ textAlign: 'center' }}>
            {showLoading ? (
              <Stack>
                <Typography variant='h5'>Update in progress</Typography>
              </Stack>
            ) : (
              <Typography variant='h5'>Update done!</Typography>
            )}
            <Typography>{`Step ${updateContent?.current_step} / ${updateContent?.total_steps}`}</Typography>
            {updateContent.eta !== undefined && <Typography>{`Time until complete ${updateContent.eta}`}</Typography>}
            {updateContent.stdout !== undefined && (
              <Stack direction='column' sx={{ maxHeight: '20vh', overflowY: 'auto', textAlign: 'left', paddingTop: '1rem' }}>
                {updateContent?.stdout.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </Stack>
            )}
            {!showLoading && (
              <Button variant='contained' onClick={onUpdateFinnish}>
                Continue
              </Button>
            )}
          </Stack>
        ) : (
          <Typography> For more information visit timkensensor.com </Typography>
        )}
      </SplashScreen>
    )
  }

  return (
    <Stack id='App'>
      <BrowserRouter>
        <SideBar>
          <Routes>
            <Route path='/' element={<DashboardTab />} />
            <Route path='/time-series' element={<TimeSeriesTab />} />
            <Route path='/series' element={<MobileSeries />} />
            <Route path='/alerts' element={<AlertTab />} />
            <Route path='/admin' element={<AdminTab version={version} />} />
          </Routes>
        </SideBar>
      </BrowserRouter>
    </Stack>
  )
}

export default App
