import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import TimeSeriesView from './TimeSeriesView'
import TimeSeriesViewSkeleton from './TimeSeriesViewSkeleton'
import { useGetSensorsQuery, useLazyGetTimeSeriesQuery, useLazyGetAlertsQuery } from '../api/localGwApi'
import debug from '../logger'
import { useNavigate } from 'react-router-dom'

const MobileSeries = () => {
  const sensor = useSelector((state) => state.series.sensor)
  const [trigger, { data: series }] = useLazyGetTimeSeriesQuery({})
  const { data: sensors } = useGetSensorsQuery()
  const [getAlerts, { data: alerts }] = useLazyGetAlertsQuery({})
  const from = useSelector((state) => state.series.range.from)
  const to = useSelector((state) => state.series.range.to)

  const loading = series === undefined
  const navigate = useNavigate()

  const onFetchData = () => {
    console.log('Fetch data')
    if (sensor.length !== 0 && sensor !== undefined) {
      debug(`TimeSeriesTab request time series with ${sensor} ${from} - ${to}`)
      trigger({
        nodeId: sensor,
        from_date: from,
        to_date: to
      })
      getAlerts({
        nodeId: sensor,
        from_date: from,
        to_date: to
      })
    }
  }

  // Redirect to sensor list if trying to load this page directly
  useEffect(() => {
    if (sensor.length === 0) {
      navigate('/time-series')
    }
  }, [navigate, sensor])

  useEffect(() => {
    onFetchData()
  }, [sensor, from, to, trigger])

  if (loading) {
    return <TimeSeriesViewSkeleton />
  } else {
    return (
      <TimeSeriesView time_series={series} sensor={sensors.filter((s) => s.serial === sensor)[0]} alerts={alerts} onRefresh={onFetchData} />
    )
  }
}

export default MobileSeries
