import { Skeleton, Stack } from '@mui/material'
import React from 'react'

const TimeSeriesViewSkeleton = () => {
  return (
    <Stack spacing={2} width={'100%'}>
      <Stack direction='row' justifyContent={'space-between'}>
        <Stack spacing={1}>
          <Skeleton variant='text' sx={{ fontSize: '2rem' }} width={'200 px'} />
          <Skeleton variant='text' sx={{ fontSize: '0.975rem' }} width={'200px'} />
          <Skeleton variant='text' sx={{ fontSize: '0.975rem' }} width={'200px'} />
        </Stack>
        <Stack spacing={1} sx={{ marginTop: '1rem', marginRight: '1rem' }}>
          <Skeleton variant='box' width={'150px'} height={'30px'} />
          <Skeleton variant='box' width={'150px'} height={'30px'} />
        </Stack>
      </Stack>
      <Skeleton variant='box' height={'15rem'} width={'100%'} />
      <Skeleton variant='box' height={'15rem'} width={'100%'} />
      <Skeleton variant='box' height={'15rem'} width={'100%'} />
      <Skeleton variant='box' height={'15rem'} width={'100%'} />
    </Stack>
  )
}

export default TimeSeriesViewSkeleton
