import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import PropTypes from 'prop-types'
import { Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material'
import { chart_date } from './Units'

const TimeSeriesChart = ({
  time,
  series,
  si,
  alerts,
  enableAnnotations,
  limits = [],
  filterNull = false,
  title = '',
  yTitle = 'y axis title',
  toolbar = true,
  zoom = true,
  min = null,
  max = null,
  red_area = null,
  by = [],
  ...rest
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.up('xs'))
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))

  const [chartSeries, setChartSeries] = useState(false)
  const [newTime, setNewTime] = useState([])

  useEffect(() => {
    let filtered_series = Object.values(series)
    let indexes = []

    for (let i = 0; i < filtered_series[0].length; ++i) {
      let notNull = false
      for (let j = 0; j < filtered_series.length; ++j) {
        if (filtered_series[j][i] !== null) {
          notNull = true
        }
      }
      if (!notNull) {
        indexes.push(i)
      }
    }

    const new_time = time.slice()
    if ((filtered_series.length > 1) | filterNull) {
      removeIndexes(new_time, indexes)
    }

    let chart_series = []
    for (const [key, ser] of Object.entries(series)) {
      const new_series = ser.slice()
      if ((filtered_series.length > 1) | filterNull) {
        removeIndexes(new_series, indexes)
      }
      chart_series.push({
        name: key,
        data: new_series
      })
    }
    setNewTime(new_time)
    setChartSeries(chart_series)
  }, [series, filterNull, time])

  const lower_limit = (min_default) => {
    if (limits.length === 2) {
      if (limits[1] < min && min !== null) {
        return limits[1] - 0.05 * limits[1]
      } else if (limits[1] < min_default) {
        return limits[1] - 0.05 * limits[1]
      }
    }

    if (min) {
      return min
    }
    return min_default
  }

  const max_limit = (max_default) => {
    if (limits.length >= 1) {
      if (limits[1] > max && max !== null) {
        return limits[1] + 0.05 * limits[1]
      } else if (limits[1] > max_default) {
        return limits[1] + 0.05 * limits[1]
      }
    }

    if (max) {
      return max
    }
    return max_default
  }

  const options = {
    chart: {
      redrawOnParentResize: true,
      zoom: {
        type: 'x',
        enabled: zoom,
        autoScaleYaxis: true
      },
      toolbar: {
        show: toolbar,
        autoSelected: 'zoom',
        export: {
          csv: {
            headerCategory: 'Timestamp',
            dateFormatter: function (timestamp) {
              return new Date(timestamp).toISOString()
            }
          }
        }
      }
    },
    noData: {
      text: 'No Data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '30px',
        fontFamily: 'Helvetica'
      }
    },
    fill: {
      type: 'gradient'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    yaxis: {
      min: lower_limit,
      max: max_limit,
      title: {
        text: yTitle,
        rotate: 0
      },
      decimalsInFloat: 3
    },
    xaxis: {
      type: 'datetime',
      categories: newTime,
      labels: {
        datetimeUTC: false
      }
    },
    tooltip: {
      shared: false,
      x: {
        show: true,
        format: chart_date(si)
      }
    }
  }

  if (enableAnnotations) {
    options.annotations = {
      xaxis: []
    }
    if (limits.length === 2) {
      options.annotations = {
        ...options.annotations,
        yaxis: []
      }
      if (limits[0] !== 0) {
        options.annotations.yaxis.push({
          y: limits[0],
          borderColor: theme.palette.warning.main,
          label: {
            show: true,
            text: 'Caution',
            style: {
              color: '#fff',
              background: theme.palette.warning.main
            }
          }
        })
      }
      if (limits[1] !== 0) {
        options.annotations.yaxis.push({
          y: limits[1],
          borderColor: theme.palette.error.main,
          label: {
            show: true,
            text: 'Warning',
            style: {
              color: '#fff',
              background: theme.palette.error.main
            }
          }
        })
      }
    }
    options.annotations.xaxis = createAnnotations(alerts, theme, by)
  }

  if (red_area) {
    if (!enableAnnotations) {
      options.annotations = {
        yaxis: []
      }
    } else if (options?.annotations?.yaxis === undefined) {
      options.annotations = {
        ...options.annotations,
        yaxis: []
      }
    }

    options.annotations.yaxis.push({
      y: 0,
      y2: red_area,
      borderColor: '#000',
      fillColor: theme.palette.error.main,
      label: {
        text: 'Critical level'
      }
    })
  }

  let height = '400px'

  if (xs || sm || md) {
    height = '400px'
  } else if (lg || xl) {
    height = '600px'
  }

  if (!chartSeries) {
    return (
      <Stack {...rest}>
        <Typography variant='h6' fontWeight='bold'>
          {title}
        </Typography>
        <Box sx={{ position: 'relative', width: '100%', height: height }}>
          <Chart options={options} type='area' style={{ position: 'absolute', width: 'inherit' }} series={[]} height={height} />
        </Box>
      </Stack>
    )
  }

  return (
    <Stack {...rest}>
      <Typography variant='h6' fontWeight='bold'>
        {title}
      </Typography>
      <Box sx={{ position: 'relative', width: '100%', height: height }}>
        <Chart options={options} type='area' style={{ position: 'absolute', width: 'inherit' }} series={chartSeries} height={height} />
      </Box>
    </Stack>
  )
}

function createAnnotations(alerts, theme, by) {
  let x_annotations = []

  for (let kk in alerts) {
    if (!by.includes(alerts[kk].by)) {
      continue
    }

    x_annotations.push({
      x: new Date(alerts[kk].from).getTime(),
      x2: new Date(alerts[kk].to).getTime(),
      label: {
        text: `${alerts[kk].by}`
      }
    })

    if (alerts[kk].type === 'error') {
      x_annotations[x_annotations.length - 1]['fillColor'] = theme.palette.error.main
    } else if (alerts[kk].type === 'warning') {
      x_annotations[x_annotations.length - 1]['fillColor'] = theme.palette.warning.main
    } else if (alerts[kk].type === 'anomaly') {
      x_annotations[x_annotations.length - 1]['fillColor'] = theme.palette.anomaly.main
    }
  }
  return x_annotations
}

function removeIndexes(arr, indexes) {
  for (var i = indexes.length - 1; i >= 0; i--) arr.splice(indexes[i], 1)
}

TimeSeriesChart.propTypes = {
  node_id: PropTypes.arrayOf(PropTypes.string),
  limits: PropTypes.arrayOf(PropTypes.number)
}

export default TimeSeriesChart
