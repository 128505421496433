export const velocity = (si) => {
  if (si) {
    return 'mm/s'
  } else {
    return 'inch/s'
  }
}

export const acceleration = (si) => {
  if (si) {
    return 'mm/s²'
  } else {
    return 'inch/s²'
  }
}

export const temperature = (si) => {
  if (si) {
    return '°C'
  } else {
    return '°F'
  }
}

export const chart_date = (si) => {
  if (si) {
    return 'hh:mm | dd.M.yyyy'
  } else {
    return 'hh:mm | M.dd.yyyy'
  }
}
