import React from 'react'
import GroupTable from './GroupTable'
import { useGetGroupQuery } from '../api/localGwApi'
import ListSkeleton from './ListSkeleton'

const GroupTab = () => {
  const { data: groups, isLoading } = useGetGroupQuery()

  if (groups === undefined || isLoading) {
    return <ListSkeleton />
  }

  return <GroupTable groups={groups} />
}

export default GroupTab
