import React from 'react'
import { Typography } from '@mui/material'

const LegalText = ({ sx }) => {
  return (
    <Typography sx={sx}>
      {`Complete product instructions, safety and warranty information, and the
      Software License Agreement are available at timkensensor.com. Please
      review all available information before using this product. By using this
      software program, you acknowledge that you have reviewed this information
      and agree on your own behalf and on behalf of your employer to the terms
      of the Software License Agreement.`}
    </Typography>
  )
}

export default LegalText
