import React, { useEffect, useState } from 'react'
import {
  Input,
  Slider,
  Box,
  MenuItem,
  Stack,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Grid,
  Switch,
  Typography,
  Button,
  IconButton,
  Skeleton,
  FormHelperText,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import {
  useAddSensorMutation,
  useDeleteSensorMutation,
  useEditSensorMutation,
  useGetUnitQuery,
  useLazyGetTimeSeriesQuery
} from '../api/localGwApi'
import EditModal from './EditModal'
import NumberInput from './NumberInput'
import useNotify from '../hooks/useNotify'
import debug from '../logger'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import TimeSeriesChart from './TimeSeriesChart'
import { StandaloneDateSelector } from './DateSelector'
import { xDaysAgo } from './Elements'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DeleteConfirmation from './DeleteConfirmation'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { temperature, velocity } from './Units'

const def = {
  name: '',
  position: '',
  type: '',
  temp_error: '',
  temp_warning: '',
  velocity_error: '',
  velocity_warning: '',
  profile: 0,
  serial: '',
  group: '',
  measurement_interval: '60min'
}

const MEASUREMENT_INTERVALS = [
  {
    value: '5min',
    name: '5 minutes'
  },
  {
    value: '30min',
    name: '30 minutes'
  },
  {
    value: '60min',
    name: '60 minutes'
  },
  {
    value: '12h',
    name: '12 hours'
  }
]

const State = {
  EditView: 'edit-view',
  AnomalyRangeSelection: 'anomaly-range-selection'
}

const EditSensor = ({
  sensor,
  onlyModal = false,
  modalClose = () => {},
  modalOpen = false,
  groups = [],
  adding = false,
  register = false,
  ...rest
}) => {
  const [form, setForm] = useState(def)
  const [errors, setErrors] = useState({})
  const [tempLimits, setTempLimits] = useState(false)
  const [velLimits, setVelLimits] = useState(false)
  const [anomaly, setAnomaly] = useState(false)
  const [onNotify] = useNotify()
  const [addSensor] = useAddSensorMutation()
  const [editSensor] = useEditSensorMutation()
  const [state, setState] = useState(State.EditView)
  const [anomalyRange, setAnomalyRange] = useState({
    startDate: sensor?.anomaly_detection?.start_time !== undefined ? new Date(sensor.anomaly_detection.start_time) : xDaysAgo(30),
    endDate: sensor?.anomaly_detection?.stop_time !== undefined ? new Date(sensor.anomaly_detection.stop_time) : new Date()
  })
  const [open, setOpen] = useState(false)
  const [disableSave, setDisableSave] = useState(true)
  const { data: unit } = useGetUnitQuery()

  const [windowSize, setWindowSize] = useState(sensor?.anomaly_detection?.window_size || 5)
  const [outliers, setOutliers] = useState(sensor?.anomaly_detection?.outliers || 2)

  useEffect(() => {
    setErrors({})

    setVelLimits(sensor?.velocity_warning !== undefined || sensor?.velocity_error !== undefined)
    setTempLimits(sensor?.temp_error !== undefined || sensor?.temp_warning !== undefined)
    setAnomaly(typeof sensor?.anomaly_detection === 'object')

    setForm({
      serial: sensor?.serial === undefined ? def.serial : sensor.serial,
      name: sensor?.name === undefined ? def.name : sensor.name,
      position: sensor?.position === undefined ? def.position : sensor.position,
      type: sensor?.type === undefined ? def.type : sensor.type,
      temp_error: sensor?.temp_error === undefined ? def.temp_error : sensor.temp_error,
      temp_warning: sensor?.temp_warning === undefined ? def.temp_warning : sensor.temp_warning,
      velocity_error: sensor?.velocity_error === undefined ? def.velocity_error : sensor.velocity_error,
      velocity_warning: sensor?.velocity_warning === undefined ? def.velocity_warning : sensor.velocity_warning,
      // profile: sensor.profile === undefined || sensor.profile === null ? def.profile : sensor.profile
      group: sensor?.group === undefined ? def.group : sensor.group,
      measurement_interval: sensor?.measurement_interval === undefined ? def.measurement_interval : sensor.measurement_interval
    })
  }, [sensor, open])

  const preWindowChange = (e) => {
    let val = e
    if (val === '') {
      return
    }
    val = parseInt(e)
    if (3 > val || val > 20) {
      return
    }
    if (isNaN(val)) {
      return
    }
    setWindowSize(val)

    // Change outliers if window size is smaller than number of outliers
    if (val < outliers) {
      setOutliers(val)
    }
  }

  const preOutliersChange = (e) => {
    let val = e
    if (val === '') {
      return
    }
    val = parseInt(e)
    if (isNaN(val)) {
      return
    }
    if (3 > val || val > windowSize) {
      return
    }
    setOutliers(val)
  }

  const onChange = (e) => {
    const id = e.target.id

    let value = undefined

    if (id === 'temp_error' || id === 'temp_warning' || id === 'velocity_error' || id === 'velocity_warning') {
      value = Number(e.target.value)
    }

    setForm({
      ...form,
      [e.target.id]: value === undefined ? e.target.value : value
    })
  }

  const onSelectChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const setError = (key, value) => {
    setErrors((ers) => ({
      ...ers,
      [key]: value
    }))
  }

  const onSuccess = () => {
    setOpen(false)
  }

  const validate = () => {
    // Filter all falsy values ( "", 0, false, null, undefined )
    // from https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript/57625661#57625661
    const send = Object.entries(form).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})

    if (form.group === '') {
      send['group'] = null
    }

    let error = false
    // Verify that name exists type exists and measurement interval is set.
    debug(send)
    if (send.name === undefined) {
      error = true
      setError('name', 'Name is required')
    }
    if (send.type === undefined) {
      error = true
      setError('type', 'Type is required')
    }
    if (send.measurement_interval === undefined) {
      error = true
      setError('measurement_interval', 'Measurement interval is required')
    }

    if (send.serial === undefined) {
      error = true
      setError('serial', 'Serial is required')
    }
    if (send.serial?.length !== 8) {
      error = true
      setError('serial', 'Serial must be 8 characters')
    }
    // Verify that warnings and errors are set if enabled
    if (tempLimits) {
      if (send.temp_warning === undefined || send.temp_warning === 0) {
        error = true
        setError('temp_warning', "Required. Can't be 0.")
      }
      if (send.temp_error === undefined || send.temp_error === 0) {
        error = true
        setError('temp_error', "Required. Can't be 0.")
      }
    } else {
      send.temp_warning = null
      send.temp_error = null
    }

    if (velLimits) {
      if (send.velocity_warning === undefined || send.velocity_warning === 0) {
        error = true
        setError('velocity_warning', "Required. Can't be 0")
      }
      if (send.velocity_error === undefined || send.velocity_error === 0) {
        error = true
        setError('velocity_error', "Required. Can't be 0.")
      }
    } else {
      send.velocity_error = null
      send.velocity_warning = null
    }

    return [send, error]
  }

  const onNext = () => {
    const [, error] = validate()
    if (error) {
      console.error(errors)
      return
    } else {
      setState(State.AnomalyRangeSelection)
    }
  }

  const beforeSubmit = (e) => {
    e.preventDefault()
    const [send, error] = validate()
    if (error) {
      return
    }

    if (anomaly) {
      send['anomaly_detection'] = {
        start_time: anomalyRange.startDate.toISOString(),
        stop_time: anomalyRange.endDate.toISOString(),
        window_size: windowSize,
        outliers: outliers
      }
    } else {
      send['anomaly_detection'] = null
    }

    let notify_text = 'Added a new sensor.'
    if (register) {
      notify_text = 'Successfully registered a new sensor'
    } else if (!(adding || register)) {
      notify_text = `Successfully saved edits on sensor ${form.name}`
    }

    debug('Sending data!', register, adding)
    debug(notify_text, send, !(register || adding))
    if (register || adding) {
      addSensor(send)
        .unwrap()
        .then((res) => {
          onNotify(res, notify_text)
          onSuccess()
        })
        .catch((res) => onNotify(res))
    } else {
      editSensor({ id: send.serial, body: send })
        .unwrap()
        .then((res) => {
          onNotify(res, notify_text)
          onSuccess()
        })
        .catch((res) => onNotify(res))
    }
  }

  const onClose = () => {
    setOpen(false)
    modalClose()
  }

  const modalTitle = () => {
    if (state === State.AnomalyRangeSelection) {
      return `Anomaly detection setup ${sensor?.serial}`
    } else if (adding || register) {
      return `Adding sensor ${form.serial}`
    } else {
      return `Editing sensor ${sensor?.serial}`
    }
  }

  return (
    <>
      {!onlyModal && (
        <>
          {adding ? (
            <Button variant='contained' onClick={() => setOpen(true)}>
              Register sensor{' '}
            </Button>
          ) : (
            <IconButton onClick={() => setOpen(true)}>
              {register ? (
                <AddCircleOutlineRoundedIcon sx={{ fill: 'black' }} fontSize='small' />
              ) : (
                <EditRoundedIcon sx={{ fill: 'black' }} fontSize='small' />
              )}
            </IconButton>
          )}
        </>
      )}
      <EditModal title={modalTitle()} onClose={onClose} sx={{ width: '800px' }} maxWidth={'lg'} open={open || modalOpen} {...rest}>
        {state === State.EditView && (
          <>
            <form id='sensor-form' onSubmit={beforeSubmit}>
              <Grid container spacing={8}>
                <Grid xs={12} md={6} item>
                  <Stack spacing={3}>
                    <Stack justifyContent='space-between' direction='row' alignItems='center'>
                      <StyledSubtitle>Sensor information</StyledSubtitle>
                      {/* Switch is just to make this align with the content on the right*/}
                      <Switch sx={{ visibility: 'hidden' }} />
                    </Stack>
                    {adding && !register && (
                      <Tooltip title='Serial number can be found from the sensor type label.'>
                        <TextField
                          id='serial'
                          helperText={errors['serial']}
                          error={errors['serial']?.length > 0}
                          required
                          onChange={onChange}
                          value={form.serial}
                          label='Serial'
                        />
                      </Tooltip>
                    )}
                    {register && (
                      <TextField
                        helperText={errors['serial']}
                        error={errors['serial']?.length > 0}
                        id='serial'
                        onChange={onChange}
                        value={form.serial}
                        label='serial'
                        disabled={true}
                      />
                    )}
                    <TextField
                      helperText={errors['name']}
                      error={errors['name']?.length > 0}
                      id='name'
                      onChange={onChange}
                      value={form.name}
                      label='Name'
                      required
                    />
                    <Tooltip title='An optional free text field to indicate the location of the sensor and/or its position on a monitored equipment.'>
                      <TextField
                        helperText={errors['position']}
                        error={errors['position']?.length > 0}
                        id='position'
                        multiline
                        onChange={onChange}
                        value={form.position}
                        label='Position'
                      />
                    </Tooltip>
                    <FormControl>
                      <InputLabel id='group'> Group </InputLabel>
                      <Select
                        name='group'
                        onChange={onSelectChange}
                        value={form.group}
                        label='Group'
                        labelId='group'
                        disabled={groups.length === 0}
                      >
                        <MenuItem key={'no_group'} value={''}>
                          --No group--
                        </MenuItem>
                        {groups.map((group) => {
                          return (
                            <MenuItem key={group.id} value={group.id}>
                              {' '}
                              {group.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <Tooltip
                      title={
                        <Box sx={{ whiteSpace: 'pre-wrap' }}>
                          {
                            'Measuring sensor:\n - collects vibration and temperature data \n - relays data to other sensors \n\nMesh relay:\n - only relays data from other sensors'
                          }
                        </Box>
                      }
                      placement='top'
                    >
                      <FormControl error={errors?.type?.length > 0}>
                        <InputLabel id='type-select'> Type * </InputLabel>
                        <Select name='type' onChange={onSelectChange} value={form.type} label='type *' labelId='type-select'>
                          <MenuItem value={'measure_route'}>Measuring sensor</MenuItem>
                          <MenuItem value={'route'}>Mesh relay sensor</MenuItem>
                        </Select>
                        <FormHelperText>{errors.type}</FormHelperText>
                      </FormControl>
                    </Tooltip>
                    <Tooltip
                      title=<Box sx={{ whiteSpace: 'pre-wrap' }}>
                        {
                          'Faster measurement interval will consume more power and shorten the lifespan of the sensor. 5 minute interval is not recommended for extended use.'
                        }
                      </Box>
                      placement='top'
                    >
                      <FormControl error={errors?.measurement_interval?.length > 0}>
                        <InputLabel id='measurement-interval-select'> Measurement interval * </InputLabel>

                        <Select
                          name='measurement_interval'
                          onChange={onSelectChange}
                          value={form.measurement_interval}
                          label='measurement-interval *'
                          labelId='measurement-interval-select'
                        >
                          {MEASUREMENT_INTERVALS.map((mi) => {
                            return (
                              <MenuItem key={`interval-${mi.value}`} value={mi.value}>
                                {' '}
                                {mi.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        <FormHelperText> {errors.measurement_interval} </FormHelperText>
                      </FormControl>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={3}>
                    <Tooltip title='Set a warning and caution limit for temperature the sensor measures'>
                      <Stack justifyContent='space-between' direction='row' alignItems='center'>
                        <StyledSubtitle> Enable temperature limits </StyledSubtitle>
                        <Switch checked={tempLimits} onChange={(e) => setTempLimits(e.target.checked)} />
                      </Stack>
                    </Tooltip>
                    <NumberInput
                      helperText={errors['temp_warning']}
                      error={errors['temp_warning']?.length > 0}
                      id='temp_warning'
                      onChange={onChange}
                      value={form.temp_warning}
                      label='Temperature caution limit'
                      disabled={!tempLimits}
                      unit={temperature(unit === 'si')}
                      required
                    />
                    <NumberInput
                      helperText={errors['temp_error']}
                      error={errors['temp_error']?.length > 0}
                      id='temp_error'
                      onChange={onChange}
                      value={form.temp_error}
                      label='Temperature warning limit'
                      disabled={!tempLimits}
                      unit={temperature(unit === 'si')}
                      required
                    />
                    <Tooltip title='Set a warning and caution limit for vibration velocity RMS value for the sensor'>
                      <Stack justifyContent='space-between' direction='row' alignItems='center'>
                        <StyledSubtitle> Enable velocity limits </StyledSubtitle>
                        <Switch checked={velLimits} onChange={(e) => setVelLimits(e.target.checked)} />
                      </Stack>
                    </Tooltip>
                    <NumberInput
                      helperText={errors['velocity_warning']}
                      error={errors['velocity_warning']?.length > 0}
                      id='velocity_warning'
                      onChange={onChange}
                      value={form.velocity_warning}
                      label='Velocity caution limit'
                      disabled={!velLimits}
                      inputProps={{ step: 0.1 }}
                      unit={velocity(unit === 'si')}
                      required
                    />
                    <NumberInput
                      helperText={errors['velocity_error']}
                      error={errors['velocity_error']?.length > 0}
                      id='velocity_error'
                      onChange={onChange}
                      value={form.velocity_error}
                      label='Velocity warning limit'
                      disabled={!velLimits}
                      inputProps={{ step: 0.1 }}
                      unit={velocity(unit === 'si')}
                      required
                    />
                    <Tooltip
                      title={
                        register || adding
                          ? 'Enable anomaly detection for the sensor. (Not available for new sensors.)'
                          : 'Enable anomaly detection for the sensor.'
                      }
                    >
                      <Stack justifyContent='space-between' direction='row' alignItems='center'>
                        <Typography color={register || adding ? 'action.disabled' : ''}> Enable anomaly detection </Typography>
                        <Switch disabled={register || adding} checked={anomaly} onChange={(e) => setAnomaly(e.target.checked)} />
                      </Stack>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            </form>
            <Stack direction='row' justifyContent='space-around' sx={{ paddingBottom: { xs: '20px' } }}>
              <Button onClick={onClose} variant='outlined'>
                Close
              </Button>
              {anomaly ? (
                <Button variant='contained' onClick={onNext}>
                  Next
                </Button>
              ) : (
                <Button type='submit' form='sensor-form' variant='contained' startIcon={<SaveRoundedIcon />}>
                  Save
                </Button>
              )}
            </Stack>
          </>
        )}
        {state === State.AnomalyRangeSelection && (
          <>
            <AnomalyRangeSelection
              range={anomalyRange}
              sensor={sensor}
              setRange={(range) => setAnomalyRange(range)}
              serial={form.serial}
              windowSize={windowSize}
              setWindowSize={preWindowChange}
              outliers={outliers}
              setOutliers={preOutliersChange}
              disableSave={(val) => setDisableSave(val)}
              si={unit === 'si'}
            />
            <Stack direction='row' justifyContent='space-around' sx={{ paddingBottom: { xs: '20px' } }}>
              <Button onClick={() => setState(State.EditView)} variant='outlined'>
                Back
              </Button>
              <Button variant='contained' onClick={beforeSubmit} disabled={disableSave} startIcon={<SaveRoundedIcon />}>
                Save
              </Button>
            </Stack>
          </>
        )}
      </EditModal>
    </>
  )
}

function StyledSubtitle({ children, ...rest }) {
  return <Typography {...rest}>{children}</Typography>
}

function AnomalyRangeSelection({ range, setRange, serial, windowSize, outliers, setWindowSize, setOutliers, disableSave, si }) {
  const [trigger, { data: series, isLoading, isFetching }] = useLazyGetTimeSeriesQuery({})

  useEffect(() => {
    trigger({
      nodeId: serial,
      from_date: range.startDate.toISOString(),
      to_date: range.endDate.toISOString()
    })
  }, [serial, range, trigger])

  useEffect(() => {
    if (series !== undefined) {
      disableSave(series.timestamp.length < 500)
    }
  }, [series, disableSave])

  const onRangeChange = (range) => {
    debug('AnomalyRangeSelection', range)
    setRange(range)
  }

  return (
    <Stack sx={{ flexDirection: 'column' }} spacing={2}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack>
          <Tooltip title='Time frame used as the learning period for anomaly detection. The learning period should cover at least 500 datapoints of normal operation.'>
            <span>
              <StandaloneDateSelector
                initialEnd={range.endDate}
                initialStart={range.startDate}
                onChange={onRangeChange}
                buttonText='filter'
                loading={isLoading || isFetching}
                disableButton={isLoading || isFetching}
              />
            </span>
          </Tooltip>
          <Tooltip title='Minimum sample amount for establishing a baseline is 500 samples.'>
            <span>
              <Typography textAlign='center'>{series?.timestamp.length} data samples within this time range</Typography>
              {series?.timestamp.length < 500 && (
                <Typography textAlign='center' color='error'>
                  Need at least 500 samples for the anomaly detection to work correctly.
                </Typography>
              )}
            </span>
          </Tooltip>
        </Stack>
      </Box>
      <Box>
        <Stack>
          {series !== undefined && !isLoading && !isFetching ? (
            <TimeSeriesChart
              style={{ width: '100%' }}
              yTitle={''}
              si={si}
              title={`Velocity RMS ${velocity(si)}`}
              series={{
                'Velocity rms (x)': series.vel_rms_x,
                'Velocity rms (y)': series.vel_rms_y,
                'Velocity rms (z)': series.vel_rms_z
              }}
              time={series.timestamp}
              toolbar={false}
              zoom={false}
            />
          ) : (
            <Skeleton variant='box' height='400px' width='100%' />
          )}
          <Tooltip title='Number of sequential samples to be analyzed by the anomaly detection. Higher values will make detection more sensitive but may increase false alerts'>
            <Box>
              <Typography>Window size</Typography>
              <Stack direction='row' spacing={2}>
                <Slider
                  value={windowSize}
                  onChange={(e) => setWindowSize(e.target.value)}
                  min={3}
                  max={20}
                  valueLabelDisplay='auto'
                  disabled={series?.timestamp.length < 500}
                />
                <Input
                  value={windowSize}
                  onChange={(e) => setWindowSize(e.target.value)}
                  size='small'
                  inputProps={{
                    step: 1,
                    min: 3,
                    max: 20,
                    type: 'number'
                  }}
                  disabled={series?.timestamp.length < 500}
                />
              </Stack>
            </Box>
          </Tooltip>
          <Tooltip title='Higher number will reduce the sensitivity of the detection'>
            <Box>
              <Typography>Outliers</Typography>
              <Stack direction='row' spacing={2}>
                <Slider
                  value={outliers}
                  onChange={(e) => setOutliers(e.target.value)}
                  min={2}
                  max={windowSize}
                  valueLabelDisplay='auto'
                  disabled={series?.timestamp.length < 500}
                />
                <Input
                  value={outliers}
                  onChange={(e) => setOutliers(e.target.value)}
                  size='small'
                  inputProps={{
                    step: 1,
                    min: 2,
                    max: windowSize,
                    type: 'number'
                  }}
                  disabled={series?.timestamp.length < 500}
                />
              </Stack>
            </Box>
          </Tooltip>
        </Stack>
      </Box>
    </Stack>
  )
}

export const DeleteSensor = ({ sensor }) => {
  const [open, setOpen] = useState(false)
  const [deleteSensor] = useDeleteSensorMutation()
  const [downloadData, setDownloadData] = useState(false)
  const [onNotify] = useNotify()

  const saveFile = async (blob) => {
    debug(blob)
    const a = document.createElement('a')
    a.download = `${sensor.serial}-time-series.csv`
    a.href = URL.createObjectURL(blob)
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
    })
    a.click()
  }

  const onConfirm = (confirm) => {
    if (confirm) {
      deleteSensor(sensor.serial)
        .unwrap()
        .then((res) => {
          if (downloadData) {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            saveFile(blob)
          }
          onNotify(res, `Successfully deleted sensor ${sensor.name}`)
        })
        .catch((res) => {
          onNotify(res)
        })
    }
    setOpen(false)
  }

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }

  const onCheckBoxClick = (e) => {
    setDownloadData(e.target.checked)
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <DeleteRoundedIcon sx={{ fill: 'black' }} fontSize='small' />
      </IconButton>
      <DeleteConfirmation title={`Are you sure you wish to delete sensor ${sensor.name}`} open={open} onConfirm={onConfirm}>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={downloadData} onChange={onCheckBoxClick} />}
              label={'Download sensor data after deletion'}
            />
          </FormGroup>
        </Stack>
      </DeleteConfirmation>
    </>
  )
}

export default EditSensor
