import * as React from 'react'
const VelocityAnomaly = ({ color, style, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    style={{
      ...style,
      height: style?.height ? style.height : 24,
      width: style?.width ? style.width : 24
    }}
    {...props}
  >
    <path fill={color} d='M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z' />
    <path
      fill='#fff'
      d='M17.528 8.545c-.341.248-.616.576-.8.955-.273.455-.428.682-.669.682-.24 0-.395-.218-.668-.682-.272-.464-.663-1.136-1.454-1.136-.791 0-1.173.654-1.455 1.136-.281.482-.427.682-.663.682-.237 0-.396-.218-.669-.682-.272-.464-.663-1.136-1.454-1.136-.791 0-1.173.654-1.455 1.136-.281.482-.422.682-.668.682-.245 0-.395-.218-.663-.682a2.568 2.568 0 0 0-.8-.955.455.455 0 0 0-.61.611.454.454 0 0 0 .146.171c.203.172.366.386.477.628.277.481.66 1.136 1.45 1.136.791 0 1.177-.655 1.455-1.136.277-.482.422-.682.668-.682.245 0 .395.218.664.682.268.463.663 1.136 1.459 1.136.795 0 1.172-.655 1.454-1.136.282-.482.423-.682.668-.682.246 0 .396.218.668.682.273.463.664 1.136 1.455 1.136s1.173-.655 1.455-1.136c.104-.24.26-.454.454-.628a.455.455 0 0 0 .21-.39.455.455 0 0 0-.655-.392ZM17.528 13.09c-.341.248-.616.576-.8.955-.273.455-.428.682-.668.682-.241 0-.396-.218-.669-.682-.272-.463-.663-1.136-1.454-1.136-.791 0-1.173.654-1.455 1.136-.281.482-.427.682-.663.682-.237 0-.396-.218-.669-.682-.272-.463-.663-1.136-1.454-1.136-.791 0-1.173.654-1.455 1.136-.281.482-.422.682-.668.682-.245 0-.395-.218-.663-.682a2.568 2.568 0 0 0-.8-.954.454.454 0 0 0-.61.61.454.454 0 0 0 .146.172c.203.171.366.385.477.627.277.482.66 1.136 1.45 1.136.791 0 1.177-.654 1.455-1.136.277-.482.422-.682.668-.682.245 0 .395.218.664.682.268.463.663 1.136 1.459 1.136.795 0 1.172-.654 1.454-1.136.282-.482.423-.682.668-.682.246 0 .396.218.669.682.272.463.663 1.136 1.454 1.136.791 0 1.173-.654 1.455-1.136.104-.24.26-.453.454-.627a.455.455 0 0 0 .191-.391.454.454 0 0 0-.636-.391Z'
    />
  </svg>
)
export default VelocityAnomaly
