import { configureStore } from '@reduxjs/toolkit'
import timeSeriesReducer from './reducers/timeSeriesReducer'
import loginReducer from './reducers/loginReducer'
import { localGwApi } from './api/localGwApi'
import splashReducer from './reducers/splashReducer'

export default configureStore({
  reducer: {
    series: timeSeriesReducer,
    [localGwApi.reducerPath]: localGwApi.reducer,
    login: loginReducer,
    splash: splashReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localGwApi.middleware)
})
