import React, { useEffect, useState } from 'react'
import { Collapse, Stack, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import debug from '../logger'
import { AlertIcon } from './Elements'

import BatteryAlertRoundedIcon from '@mui/icons-material/BatteryAlertRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useGetAlertsQuery } from '../api/localGwApi'
import { useSelector } from 'react-redux'

const GroupList = ({ groups, sensors, sx, smallIcons = false, onSensorClick = () => {}, ...rest }) => {
  const [selected, setSelected] = useState({})
  const sensor_serial = useSelector((state) => state.series.sensor)

  const { data: alerts } = useGetAlertsQuery({
    states: ['new', 'active']
  })

  const onSelectRow = (sensor) => {
    debug(selected)
    onSensorClick(sensor.serial)
  }

  useEffect(() => {
    setSelected({
      [sensor_serial]: !selected[sensor_serial]
    })
  }, [sensor_serial])

  if (sensors?.length === 0) {
    return <div>No sensors yet.</div>
  }

  const noGroup = sensors.filter((sensor) => sensor.group === undefined)
  return (
    <Stack>
      <Typography variant='h6' sx={{ backgroundColor: 'primary.main', padding: '1rem', color: 'alternativeBackground.text' }}>
        Sensors
      </Typography>
      <List {...rest} sx={{ ...sx, paddingTop: 0, backgroundColor: 'highlightColor' }}>
        {noGroup.length !== 0 && (
          <>
            <SensorList smallIcons={smallIcons} onSelectRow={onSelectRow} selected={selected} sensors={noGroup} alerts={alerts} />
          </>
        )}
        {groups.map((group) => {
          const filtered = sensors.filter((sensor) => sensor.group === group.id)
          if (filtered.length === 0) {
            return false
          } else {
            return (
              <Group key={group.id} group={group} selected={selected} sensors={filtered} alerts={alerts}>
                <SensorList smallIcons={smallIcons} onSelectRow={onSelectRow} selected={selected} sensors={filtered} alerts={alerts} />
              </Group>
            )
          }
        })}
      </List>
    </Stack>
  )
}

function Group({ group, children, sensors, alerts, selected }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    sensors?.forEach((sensor) => {
      if (selected[sensor.serial]) {
        setOpen(true)
      }
    })
  }, [sensors, selected])

  const handleClick = () => {
    setOpen(!open)
  }

  const node_ids = sensors?.map((sensor) => sensor.serial)
  const filtered = alerts?.filter((alert) => node_ids.includes(alert.node_id))
  const unique = calculateUniqueAlerts(filtered)

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <ListItem
          sx={{
            borderRadius: '2px'
          }}
        >
          <ListItemText primary={group.name} />
          <Stack direction='row' spacing={1}>
            {unique !== 0 && <AlertIcon smallIcons type='mui-error' spacing={0} amount={unique} />}
          </Stack>
          {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </ListItem>
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

function SensorList({ onSelectRow, selected, sensors, alerts }) {
  return (
    <List sx={{ paddingLeft: '1rem' }} component='div' disablePadding>
      {sensors.map((sensor) => {
        const filtered = alerts?.filter((alert) => alert.node_id === sensor.serial)

        const [warnings, errors, anomaly] = calculateAlerts(filtered)

        debug(`Sensor ${sensor.serial} 
                  Warnings ${warnings}
                  Errors ${errors}
                  Anomaly ${anomaly}`)

        return (
          <ListItemButton key={sensor.serial} onClick={() => onSelectRow(sensor)}>
            <ListItemText sx={{ color: selected[sensor.serial] ? 'primary.main' : '' }} primary={sensor.name} />
            <Stack direction='row' spacing={1}>
              {errors > 0 && <AlertIcon smallIcons type='error' by='anomaly' spacing={0} />}
              {warnings > 0 && <AlertIcon smallIcons type='warning' by='anomaly' spacing={0} />}
              {anomaly > 0 && <AlertIcon smallIcons type='anomaly' by='anomaly' spacing={0} />}
              {sensor.battery_alert && <BatteryAlertRoundedIcon color='error' />}
            </Stack>
          </ListItemButton>
        )
      })}
    </List>
  )
}

function calculateAlerts(filtered) {
  const warnings = filtered?.reduce((acc, row) => {
    if (row.type === 'warning') {
      return acc + 1
    }
    return acc
  }, 0)

  const errors = filtered?.reduce((acc, row) => {
    if (row.type === 'error') {
      return acc + 1
    }
    return acc
  }, 0)

  const anomaly = filtered?.reduce((acc, row) => {
    if (row.type === 'anomaly') {
      return acc + 1
    }
    return acc
  }, 0)

  return [warnings, errors, anomaly]
}

function calculateUniqueAlerts(alerts) {
  const filtered = alerts?.map((al) => al.node_id)

  const uniqueItems = [...new Set(filtered)]
  return uniqueItems.length
}

export default GroupList
