import { useSnackbar } from 'notistack'
import debug from '../logger'

const useNotify = () => {
  const { enqueueSnackbar } = useSnackbar()

  const onNotify = (res, msg) => {
    debug('Notification', res)
    const { error, data, status, statusText } = res
    debug('Notification', res, error, data, status)
    if (error !== undefined) {
      if (typeof error.data === 'object') {
        enqueueSnackbar(error.data.detail, { variant: 'error' })
      } else if (typeof error.data === 'string') {
        enqueueSnackbar(error.data, { variant: 'error' })
      } else {
        enqueueSnackbar('Unknown error!', { variant: 'error' })
      }
    } else if (status !== 200 && status !== undefined) {
      debug('Notification data', data)
      if (typeof data === 'string') {
        enqueueSnackbar(data, { variant: 'error' })
      } else if (typeof data === 'object') {
        enqueueSnackbar(data.detail, { variant: 'error' })
      } else if (statusText !== undefined) {
        enqueueSnackbar(statusText, { variant: 'error' })
      } else {
        enqueueSnackbar('Unknown error!', { variant: 'error' })
      }
    } else {
      enqueueSnackbar(msg, { variant: 'success' })
    }
  }

  return [onNotify]
}

export default useNotify
