import React from 'react'
import { Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { PrettyDate } from './Elements'
import EditSensor from './EditSensor'
import { StyledCell } from './VirtualList'
import PropTypes from 'prop-types'

const NodeInventoryList = ({ sensors, groups, ...rest }) => {
  return (
    <TableContainer {...rest}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'primary.main', color: 'alternativeBackground.text' }}>
            <StyledCell sx={{ color: 'alternativeBackground.text', width: '100px', fontWeight: 'bold' }}> Serial </StyledCell>
            <StyledCell sx={{ color: 'alternativeBackground.text', width: '200px', fontWeight: 'bold' }}> Last message </StyledCell>
            <StyledCell sx={{ color: 'alternativeBackground.text' }}>
              <Stack direction='row' flexDirection='row-reverse'>
                Add
              </Stack>
            </StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sensors.map((sensor) => (
            <TableRow key={sensor.serial}>
              <StyledCell component='th' scope='row'>
                {sensor.serial}
              </StyledCell>
              <StyledCell>
                <PrettyDate date_string={sensor.last_message} />
              </StyledCell>
              <StyledCell sx={{ maxWidth: '30px' }}>
                <Stack direction='row' flexDirection='row-reverse'>
                  <EditSensor sensor={sensor} groups={groups} register />
                </Stack>
              </StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

NodeInventoryList.propTypes = {
  sensors: PropTypes.arrayOf(
    PropTypes.shape({
      serial: PropTypes.string,
      last_message: PropTypes.string
    })
  )
}

export default NodeInventoryList
