import React from 'react'
import { Stack, Button } from '@mui/material'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const EditFormButtons = ({ onClose, enableDelete, ...rest }) => {
  return (
    <Stack direction='row' justifyContent='space-around' {...rest}>
      <Button onClick={onClose} variant='outlined'>
        Close
      </Button>
      {enableDelete && (
        <Button variant='contained' startIcon={<DeleteRoundedIcon />} color='error'>
          Delete
        </Button>
      )}
      <Button type='submit' variant='contained' startIcon={<SaveRoundedIcon />}>
        Save
      </Button>
    </Stack>
  )
}

export default EditFormButtons
