import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  Switch,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip
} from '@mui/material'
import debug from '../logger'
import { PasswordInput } from './Elements'
import { useGetSettingsQuery, useSendTestEmailMutation, useSetSettingsMutation } from '../api/localGwApi'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import useNotify from '../hooks/useNotify'
import { useDispatch } from 'react-redux'
import { setSplash } from '../reducers/splashReducer'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'

const def_email = {
  server: '',
  port: '',
  from: '',
  username: '',
  password: '',
  frequency: 'instant',
  tls: true,
  auth: true
}

const def_static = {
  ip: '',
  netmask: '',
  gateway: '',
  dns: ''
}

const def = {
  'dhcp-client': true,
  unit: 'si',
  manual_time: true,
  static: def_static,
  email: def_email,
  ntp: '',
  time: dayjs(),
  email_alerts: false,
  current_ip: '',
  ethernet_mac: '',
  email_recipients: ''
}

const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const port_regex = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
// From https://www.regextester.com/97579
const netmask =
  /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/
const ip_address_regex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(?::\d{0,5})?\b$/

const SettingsTab = () => {
  // All errors from different forms
  const [values, setValues] = useState(def)
  const [errors, setErrors] = useState({
    ...def_email,
    ...def_static,
    email_recipients: '',
    ntp: '',
    time: ''
  })
  const [recipientChanged, setRecipientChanged] = useState(false)
  const [emailInfoChanged, setEmailInfoChanged] = useState(false)

  const [onNotify] = useNotify()
  const { data: settings, isLoading: settingsLoading } = useGetSettingsQuery()
  const [editSettings] = useSetSettingsMutation()
  const [sendTestEmail] = useSendTestEmailMutation()

  const dispatch = useDispatch()

  // Set the loaded settings from settings endpoint
  useEffect(() => {
    if (!settingsLoading && settings !== undefined) {
      setValues({
        ...settings,
        ntp: Array.isArray(settings?.ntp) ? settings?.ntp.join(';') : '',
        current_ip: settings?.static?.ip,
        static: {
          ip: settings?.static?.ip === undefined ? def_static.ip : settings.static.ip,
          netmask: settings?.static?.netmask === undefined ? def_static.netmask : settings.static.netmask,
          gateway: settings?.static?.gateway === undefined ? def_static.gateway : settings.static.gateway,
          dns: settings?.static?.dns === undefined ? def_static.dns : settings.static.dns.join(';')
        },
        time: dayjs(settings.time),
        email_recipients: Array.isArray(settings?.email_recipients) ? settings.email_recipients.join(';') : '',
        email: {
          ...settings.email,
          username: settings.email.username === undefined ? def_email.username : settings.email.username,
          password: '',
          port: settings.email.port || def_email.port,
          tls: settings.email.tls === undefined ? def_email.tls : settings.email.tls,
          auth: settings.email.auth === undefined ? def_email.auth : settings.email.auth
        }
      })
    }
    // can't include the on EthChange since it will cause a render loop
    // eslint-disable-next-line
  }, [settings])

  useEffect(() => {
    if (values.manual_time) {
      setError('ntp', '')
    }
  }, [values.manual_time])

  useEffect(() => {
    if (values.email_alerts) {
      setErrors((old) => ({
        ...old,
        ...def_email,
        ntp: '',
        time: '',
        email_recipients: ''
      }))
    }
  }, [values.email_alerts])

  const onChange = (e, form, key) => {
    let id = e?.target?.id
    let value = e?.target?.value
    if (key !== undefined) {
      id = key
      value = e
    }

    setValues((old) => ({
      ...old,
      [form]: {
        ...old[form],
        [id]: value
      }
    }))
  }

  const onSimpleChange = (key, value) => {
    setValues((old) => ({
      ...old,
      [key]: value
    }))
  }

  const setError = (key, value) => {
    setErrors((ers) => ({
      ...ers,
      [key]: value
    }))
  }

  const onNtpChange = (e) => {
    onSimpleChange('ntp', e.target.value)
  }

  const onSmtpFormChange = (e, id) => {
    onChange(e, 'email', id)
    setEmailInfoChanged(true)
  }

  const onRecipientChange = (e) => {
    onSimpleChange('email_recipients', e.target.value)
    setRecipientChanged(true)
  }

  const onEthChange = (e, id) => {
    if (values['dhcp-client']) {
      return
    }
    onChange(e, 'static', id)
  }

  const onTimeChange = (e) => {
    onSimpleChange('time', e)
  }

  const onEnableSmtp = (e) => {
    onSimpleChange('email_alerts', e.target.checked)
  }

  const onEnableAuth = (e) => {
    onSmtpFormChange(e.target.checked, 'auth')
  }

  const onEnableTls = (e) => {
    onSmtpFormChange(e.target.checked, 'tls')
  }

  const onEnableNtp = (e) => {
    onSimpleChange('manual_time', !e.target.checked)
  }

  const onEnableDhcp = (e) => {
    onSimpleChange('dhcp-client', e.target.checked)
  }

  const onUnitChange = (e) => {
    onSimpleChange('unit', e.target.value)
  }

  const onEmailFreqChange = (e) => {
    onChange(e.target.value, 'email', 'frequency')
  }

  const validateEmail = () => {
    let error = false
    // Validate emails
    if (!email_regex.test(values.email.from)) {
      setError('from', 'Invalid email address')
      error = true
    } else {
      setError('from', '')
    }
    // Validate email server port number
    if (!port_regex.test(values.email.port)) {
      setError('port', 'A number between 1-65535')
      error = true
    } else {
      setError('port', '')
    }

    if (!ip_address_regex.test(values.email.server) && !isValidUrl(values.email.server)) {
      setError('server', 'Not a valid hostname')
      error = true
    } else {
      setError('server', '')
    }

    if (values.auth) {
      if (values.email.username.length === 0 && values.email.auth) {
        setError('username', "Can't be empty")
        error = true
      } else {
        setError('username', '')
      }

      if (values.email.password.length === 0 && values.email.auth) {
        setError('password', "Can't be empty")
        error = true
      } else {
        setError('password', '')
      }
    }

    return error
  }

  const validateEmailRecipients = () => {
    let error = false
    const recipients = values.email_recipients.split(';')
    if (recipients.length > 0) {
      let email_error = false
      for (const i in recipients) {
        if (!email_regex.test(recipients[i])) {
          email_error = true
        }
      }

      if (email_error) {
        error = true
        setError('email_recipients', 'For example "john.doe@example.com;alice.doe@example.com"')
      } else {
        setError('email_recipients', '')
      }
    } else {
      setError('email_recipients', "Can't be empty")
    }
    return [error, recipients]
  }

  const beforeSave = () => {
    debug('SettingsTab before validation', values)
    debug('SettingsTab', settings)

    let send = {}
    // Validate ip
    let error = false
    if (!values['dhcp-client']) {
      send.static = {}
      if (!values.static.ip.match(ip_address_regex)) {
        setError('ip', 'For example 192.168.1.1')
        error = true
      } else {
        setError('ip', '')
        send.static.ip = values.static.ip
      }

      if (!values.static.netmask.match(netmask)) {
        setError('netmask', 'For example 255.255.255.0')
        error = true
      } else {
        setError('netmask', '')
        send.static.netmask = values.static.netmask
      }

      if (!ip_address_regex.test(values.static.gateway) && values.static.gateway.length > 0) {
        setError('gateway', 'For example 192.168.1.1')
        error = true
      } else {
        setError('gateway', '')
        send.static.gateway = values.static.gateway
      }

      if (values.static.gateway.length > 0) {
        let dnss = values.static.dns.split(';')
        let dns_error = false
        for (const i in dnss) {
          if (!ip_address_regex.test(dnss[i])) {
            dns_error = true
          }
        }
        if (dns_error) {
          setError('dns', 'For example 1.1.1.1; 8.8.8.8')
          error = true
        } else {
          setError('dns', '')
        }
        send.static.dns = dnss
      } else {
        setError('dns', "Can't be empty")
      }
    }

    // Check ntp
    if (!values.manual_time) {
      const ntps = values.ntp.split(';')

      let ntp_error = false
      for (const i in ntps) {
        debug(ip_address_regex.test(ntps))
        debug(isValidUrl(ntps[i]))
        if (!ip_address_regex.test(ntps[i]) && !isValidUrl(ntps[i])) {
          ntp_error = true
        }
      }

      send.ntp = ntps
      if (ntp_error) {
        setError('ntp', 'For example "0.pool.ntp.org;192.168.1.1"')
        error = true
      }
    } else {
      setError('ntp', '')
    }

    debug(recipientChanged)
    debug(emailInfoChanged)

    if (values.email_alerts) {
      const email_error = validateEmail()
      if (email_error) {
        error = true
      }
      if (emailInfoChanged) {
        send.email = {
          ...values.email,
          port: parseInt(values.email.port)
        }
      }
      if (recipientChanged) {
        const [recipient_error, recipients] = validateEmailRecipients()
        if (recipient_error) {
          error = true
        }
        send.email_recipients = recipients
      }
    }

    // Verify that manual time is valid
    if (values.manual_time) {
      if (values.time === undefined) {
        setError('time', 'Need to set system time when not using Timeserver')
        error = true
      } else {
        setError('time', '')
        send.time = values.time.toISOString()
      }
    }

    if (!error) {
      send = {
        ...send,
        unit: values.unit,
        manual_time: values.manual_time,
        'dhcp-client': values['dhcp-client'],
        email_alerts: values.email_alerts
      }
      debug('SettingsTab can send. No errors')
      debug('SettingsTab to be sent', send)

      // Remove username and password if auth disabled
      if (!values.email.auth) {
        delete send?.email?.username
        delete send?.email?.password
      }

      setErrors({
        ...def_email,
        ...def_static,
        email_recipients: '',
        ntp: '',
        time: ''
      })

      setEmailInfoChanged(false)
      setRecipientChanged(false)

      editSettings(send)
        .unwrap()
        .then((res) => {
          onNotify(res, 'New settings saved! Gateway rebooting ...')
          setTimeout(() => {
            dispatch(setSplash(true))
          }, 5000)
        })
        .catch((res) => onNotify(res))
    }
  }

  const onSendTestEmail = () => {
    debug(values)
    if (validateEmail()) {
      return
    }

    const [error, recipients] = validateEmailRecipients()
    debug(error, recipients)
    if (error) {
      return
    }

    sendTestEmail({
      email: {
        ...values.email,
        port: parseInt(values.email.port)
      },
      email_recipients: recipients
    })
      .unwrap()
      .then((res) => onNotify(res, 'Test email sent'))
      .catch((res) => onNotify(res))
  }

  if (settingsLoading) {
    return <CircularProgress />
  }

  return (
    <Box sx={{ padding: '1rem', overflow: 'auto' }}>
      <Grid container spacing={2} sx={{ justifyContent: 'space-evenly' }}>
        <Grid item xs={12} md={6} lg={3}>
          <Tooltip title='To be able to send alert emails, in addition to configuring the email settings, the gateway must be connected to network with an Ethernet cable and the Ethernet settings must be configured'>
            <Stack direction='row' justifyContent='center' spacing={1}>
              <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Alert emails</Typography>
              <HelpOutlineRoundedIcon />
            </Stack>
          </Tooltip>
          <Stack spacing={3}>
            {/* */}
            <Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography> Enable</Typography>
                <Switch checked={values.email_alerts} onChange={onEnableSmtp} />
              </Stack>
              <TextField
                label='Email server'
                id='server'
                value={values.email.server}
                onChange={onSmtpFormChange}
                error={errors.server.length > 0}
                helperText={errors.server}
                disabled={!values.email_alerts}
                required
              />
            </Stack>
            <Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography> TLS </Typography>
                <Switch checked={values.email.tls} onChange={onEnableTls} />
              </Stack>
              <TextField
                label={values.email.tls ? 'Email port (TLS)' : 'Email port (NO TLS)'}
                id='port'
                value={values.email.port}
                onChange={onSmtpFormChange}
                error={errors.port.length > 0}
                helperText={errors.port}
                disabled={!values.email_alerts}
                required
              />
            </Stack>
            <TextField
              label='Sender email'
              id='from'
              value={values.email.from}
              onChange={onSmtpFormChange}
              error={errors.from.length > 0}
              helperText={errors.from}
              disabled={!values.email_alerts}
              required
            />
            <Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography> Enable authentication </Typography>
                <Switch checked={values.email.auth} onChange={onEnableAuth} />
              </Stack>
              <TextField
                label='Username'
                id='username'
                onChange={onSmtpFormChange}
                value={values.email.username}
                error={errors.username.length > 0}
                helperText={errors.username}
                disabled={!values.email_alerts}
                sx={{ display: values.email.auth ? '' : 'none' }}
                required
              />
            </Stack>
            <PasswordInput
              id='password'
              onChange={onSmtpFormChange}
              value={values.email.password}
              label='Password'
              error={errors.password.length > 0}
              helperText={errors.password}
              disabled={!values.email_alerts}
              required
              sx={{ display: values.email.auth ? '' : 'none' }}
            />
            <Tooltip title='A semicolon separated list of email recipients. For example "john.doe@example.com;alice.doe@example.com"'>
              <TextField
                label='Receiver email/s'
                id='recipients'
                value={values.email_recipients}
                error={errors.email_recipients.length > 0}
                helperText={errors.email_recipients}
                onChange={onRecipientChange}
                disabled={!values.email_alerts}
                required
              />
            </Tooltip>
            <FormControl fullWidth disabled={!values.email_alerts}>
              <InputLabel id='email-freq-select'> Email frequency </InputLabel>
              <Select value={values.email.frequency} onChange={onEmailFreqChange} label='Email frequency' id='email-freq-select'>
                <MenuItem value={'daily'}>Daily</MenuItem>
                <MenuItem value={'instant'}>Instant</MenuItem>
              </Select>
            </FormControl>
            <Button variant='contained' onClick={onSendTestEmail}>
              Send test email
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Tooltip title='Ethernet settings are needed to connect the gateway to network. Connectivity is required to send email alerts or to access the gateway application via internal network.'>
            <Stack direction='row' justifyContent='center' spacing={1}>
              <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Ethernet settings</Typography>
              <HelpOutlineRoundedIcon />
            </Stack>
          </Tooltip>
          <Stack spacing={3}>
            <Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography> DHCP </Typography>
                <Switch checked={values['dhcp-client']} onChange={onEnableDhcp} />
              </Stack>
              <Tooltip title='Read only'>
                <TextField
                  label='MAC address'
                  value={values.ethernet_mac}
                  onChange={() => {
                    /* Can't change the value */
                  }}
                />
              </Tooltip>
            </Stack>
            <Tooltip title={values['dhcp-client'] ? 'Read only' : ''}>
              <TextField
                label={values['dhcp-client'] ? 'Current ip' : 'Static ip'}
                id='ip'
                value={values['dhcp-client'] ? values.current_ip : values.static.ip}
                onChange={onEthChange}
                error={errors.ip.length > 0}
                helperText={errors.ip}
                required
              />
            </Tooltip>
            <TextField
              label='Netmask'
              id='netmask'
              value={values.static.netmask}
              onChange={onEthChange}
              error={errors.netmask.length > 0}
              helperText={errors.netmask}
              disabled={values['dhcp-client']}
              required
            />
            <TextField
              label='Default gateway'
              id='gateway'
              value={values.static.gateway}
              onChange={onEthChange}
              error={errors.gateway.length > 0}
              helperText={errors.gateway}
              disabled={values['dhcp-client']}
            />
            <Tooltip title='A semicolon separated list of dns addresses. For example "1.1.1.1;8.8.8.8"'>
              <TextField
                label='DNS'
                id='dns'
                value={values.static.dns}
                onChange={onEthChange}
                error={errors.dns.length > 0}
                helperText={errors.dns}
                disabled={values['dhcp-client']}
              />
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Stack spacing={2}>
            <div>
              <Tooltip title='When the gateway is connected to internal network which has a network time server, it can be used to get the right time to gateway automatically'>
                <Stack direction='row' justifyContent='center' spacing={1}>
                  <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Timeserver</Typography>
                  <HelpOutlineRoundedIcon />
                </Stack>
              </Tooltip>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography> Enable</Typography>
                <Switch checked={!values.manual_time} onChange={onEnableNtp} />
              </Stack>
              <Tooltip title='A semicolon separated list of NTP servers. For example "0.pool.ntp.org;192.168.1.5"'>
                <TextField
                  label='NTP'
                  id='ntp'
                  value={values.ntp}
                  onChange={onNtpChange}
                  disabled={values.manual_time}
                  error={errors.ntp.length > 0}
                  helperText={errors.ntp}
                  style={{ width: '100%' }}
                />
              </Tooltip>
            </div>
            <Stack spacing={1}>
              <Tooltip title='Correct time can be set to gateway manually or fetched from the device used to access the application'>
                <Stack direction='row' justifyContent='center' spacing={1}>
                  <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Gateway time</Typography>
                  <HelpOutlineRoundedIcon />
                </Stack>
              </Tooltip>
              <DateTimePicker
                label='Time'
                error={errors.time.length > 0}
                helperText={errors.time}
                value={values.time}
                disabled={!values.manual_time}
                ampm={false}
                onChange={onTimeChange}
                id='time'
              />
              <Button variant='contained' onClick={() => onTimeChange(dayjs())}>
                Get time from this device
              </Button>
            </Stack>
            <Stack spacing={1}>
              <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Units</Typography>
              <FormControl fullWidth>
                <InputLabel id='unit-select'>System of measurement</InputLabel>
                <Select value={values.unit} onChange={onUnitChange} label='System of measurement' id='unit-select'>
                  <MenuItem value={'si'}>Metric</MenuItem>
                  <MenuItem value={'imperial'}>Imperial</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <Button variant='contained' onClick={beforeSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

function isValidUrl(string) {
  try {
    new URL(`https://${string}`)
    return true
  } catch (err) {
    return false
  }
}

export default SettingsTab
