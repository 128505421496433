import React, { useState } from 'react'
import { IconButton, Paper, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEditAlertMutation } from '../api/localGwApi'

import { AlertIcon, PrettyDate } from './Elements'
import debug from '../logger'
import { sensorChanged, rangeChanged } from '../reducers/timeSeriesReducer'
// Icons
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import EditAlert from './EditAlert'
import useNotify from '../hooks/useNotify'
import VirtualList from './VirtualList'

const AlertList = ({ alerts, showActive, showNew, showAcknowledged, dismiss = false, component = Paper, ...rest }) => {
  const [currentAlert, setCurrentAlert] = useState(undefined)
  const [editing, setEditing] = useState(false)
  const [editAlert] = useEditAlertMutation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [onNotify] = useNotify()

  const onSubmit = (e, form) => {
    debug(e, form)
    editAlert(form)
      .unwrap()
      .then((res) => onNotify(res, 'Alert edits saved successfully!'))
      .catch((res) => onNotify(res))
  }

  const onChart = (e, alert) => {
    e.stopPropagation()
    dispatch(sensorChanged(alert.node_id))
    dispatch(
      rangeChanged({
        from: alert.from,
        to: alert.to
      })
    )
    navigate('/time-series')
  }

  const onDismissAlert = (e, alert) => {
    e.stopPropagation()
    debug('Dismiss', alert)
    setCurrentAlert(alert)
    setEditing(true)
  }

  const onEditClose = () => {
    setCurrentAlert(undefined)
    setEditing(false)
  }

  const columns = [
    {
      field: 'icon',
      headerName: '',
      width: 30,
      align: 'center',
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => <AlertIcon smallIcons type={value.type} by={value.by} />,
      xs: true,
      noSort: true
    },
    {
      field: 'type',
      headerName: 'Alert Type',
      width: 120,
      align: 'center',
      renderCell: ({ value }) => {
        let show = value
        if (value === 'error') {
          show = 'warning'
        } else if (value === 'warning') {
          show = 'caution'
        }
        return (
          <Typography sx={{ fontSize: 'inherit' }} textTransform='capitalize'>
            {show}{' '}
          </Typography>
        )
      },
      xs: true
    },
    {
      field: 'by',
      headerName: 'Caused by',
      width: 130,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 'inherit' }} textTransform='capitalize'>
          {params.value}{' '}
        </Typography>
      ),
      md: true
    },
    {
      field: 'reason',
      headerName: 'Reason',
      width: 130
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 130
    },
    {
      field: 'from',
      headerName: 'Started',
      width: 160,
      xs: true,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 'inherit' }}>
          {' '}
          <PrettyDate date_string={params.value}></PrettyDate>{' '}
        </Typography>
      )
    },
    {
      field: 'to',
      headerName: 'Ended',
      width: 160,
      lg: true,
      renderCell: (params) => (
        <Typography sx={{ fontSize: 'inherit' }}>
          {' '}
          <PrettyDate date_string={params.value}></PrettyDate>{' '}
        </Typography>
      )
    },
    {
      field: 'trend',
      headerName: 'Trend',
      noSort: true,
      width: 80,
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => {
        return (
          <IconButton
            disabled={isNaN(Date.parse(value.from)) || isNaN(Date.parse(value.to))}
            aria-label='series'
            color='primary'
            onClick={(e) => onChart(e, value)}
          >
            <TimelineRoundedIcon />
          </IconButton>
        )
      },
      sm: true
    },
    {
      field: 'name',
      headerName: 'Node name',
      width: 150,
      lg: true
    },
    {
      field: 'state',
      headerName: 'State',
      width: 120,
      renderCell: ({ value }) => (
        <Typography sx={{ fontSize: 'inherit' }} textTransform='capitalize'>
          {value}
        </Typography>
      ),
      md: true
    },
    {
      field: 'button',
      noSort: true,
      headerName: dismiss ? 'Dismiss' : 'Edit',
      width: 60,
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => (
        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={(e) => onDismissAlert(e, value)}>
          <EditRoundedIcon sx={{ fill: 'black' }} />
        </IconButton>
      ),
      xs: true
    }
  ]

  const filteredAlerts = alerts.filter((alert) => {
    if (alert.state === 'new' && showNew) {
      return true
    } else if (alert.state === 'active' && showActive) {
      return true
    } else if (alert.state === 'acknowledged' && showAcknowledged) {
      return true
    } else {
      return false
    }
  })

  const Edit = () => {
    if (currentAlert !== undefined) {
      return (
        <EditAlert
          open={editing}
          current={currentAlert}
          dismissal={true}
          onClose={onEditClose}
          onSubmit={onSubmit}
          title={dismiss ? 'Dismiss alert' : 'Editing alert'}
        />
      )
    }
    return <></>
  }

  return (
    <>
      <VirtualList rows={filteredAlerts} columns={columns} component={component} defaultCollapse {...rest} />
      <Edit />
    </>
  )
}

export default AlertList
