import React, { useEffect } from 'react'
import { Grid, useTheme, useMediaQuery, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GroupList from './GroupList'
import debug from '../logger'
import TimeSeriesView from './TimeSeriesView'
import { useDispatch } from 'react-redux'
import { sensorChanged } from '../reducers/timeSeriesReducer'
import { useGetGroupQuery, useGetSensorsQuery, useGetUnitQuery, useLazyGetAlertsQuery, useLazyGetTimeSeriesQuery } from '../api/localGwApi'
import ListSkeleton from './ListSkeleton'
import TimeSeriesViewSkeleton from './TimeSeriesViewSkeleton'

const TimeSeriesTab = () => {
  const dispatch = useDispatch()
  const from = useSelector((state) => state.series.range.from)
  const to = useSelector((state) => state.series.range.to)
  const sensor = useSelector((state) => state.series.sensor)

  const navigate = useNavigate()

  const { data: sensors, isLoading: sensorLoading } = useGetSensorsQuery()
  const { data: groups } = useGetGroupQuery()
  // const series = useSelector( state => state.series.data);
  const [trigger, { data: series, isLoading, isFetching }] = useLazyGetTimeSeriesQuery({})
  const [getAlerts, { data: alerts }] = useLazyGetAlertsQuery({})
  const { data: unit } = useGetUnitQuery()

  const onFetchData = () => {
    console.log('Fetch data')
    if (sensor.length !== 0 && sensor !== undefined) {
      debug(`TimeSeriesTab request time series with ${sensor} ${from} - ${to}`)
      trigger({
        nodeId: sensor,
        from_date: from,
        to_date: to
      })
      getAlerts({
        nodeId: sensor,
        from_date: from,
        to_date: to
      })
    }
  }

  useEffect(() => {
    onFetchData()
  }, [sensor, from, to, trigger, getAlerts])

  const theme = useTheme()
  const small_screen = useMediaQuery(theme.breakpoints.down('md'))
  const hide_sensors = small_screen && sensor !== undefined

  const onSensorClick = (serial) => {
    debug(serial)
    dispatch(sensorChanged(serial))

    if (small_screen) {
      navigate('/series')
    }
  }

  if (sensorLoading) {
    return <></>
  }

  if (sensors?.length === 0) {
    return <Typography>No sensors yet. Please go to admin page to add your first sensor.</Typography>
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={4}
        xl={2}
        sx={{
          overflowY: 'none',
          maxHeight: 'calc(100vh - 94px)'
        }}
      >
        {sensors !== undefined && groups !== undefined ? (
          <GroupList sensors={sensors} groups={groups} onSensorClick={onSensorClick} />
        ) : (
          <ListSkeleton row_count={10} />
        )}
      </Grid>
      {!small_screen && (
        <Grid
          item
          xs={12}
          md={8}
          xl={10}
          sx={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 94px)',
            paddingBottom: '2rem',
            display: hide_sensors ? 'none' : 'flex'
          }}
        >
          {series !== undefined && sensor !== undefined && alerts !== undefined && !isLoading && !isFetching ? (
            <TimeSeriesView
              time_series={series}
              sensor={sensors.filter((s) => s.serial === sensor)[0]}
              alerts={alerts}
              si={unit === 'si'}
              onRefresh={onFetchData}
            />
          ) : (
            <TimeSeriesViewSkeleton />
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default TimeSeriesTab
